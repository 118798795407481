import { Navigate } from "react-router-dom";

const Auth = ({ children }) => {
  if (localStorage.getItem("globalToken")) {
    return children;
  }

  // alert("Login or Subscribed");
  return <Navigate to="/UserLogin" />;
};
export default Auth;
