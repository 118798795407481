import React from "react";
import { Link } from "react-router-dom";
import Layout from "../../Layout/Layout";
import Carousel from "react-bootstrap/Carousel";
import "../MainPage/MainPage.css";

import slider1 from "../../Assets/slider1.jpg";
import slider2 from "../../Assets/slider2.jpg";
import slider3 from "../../Assets/slider3.jpg";
import slider4 from "../../Assets/slider4.jpg";
// import slider5 from "../../Assets/slider5.jpg";
// import slider6 from "../../Assets/slider6.jpg";
// import slider7 from "../../Assets/slider7.jpg";

const Mainpage = () => {
  return (
    <Layout>
      <div className="screen-container">
        <div className="row">
          <div className="col-12">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-2 ">
                <Carousel data-bs-theme="light" className="carousel slide">
                  <Carousel.Item>
                    <img
                      className="d-block w-100 slider_container"
                      src={slider1}
                      alt="First slide"
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      className="d-block w-100 slider_container"
                      src={slider2}
                      alt="Second slide"
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      className="d-block w-100 slider_container"
                      src={slider3}
                      alt="Third slide"
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      className="d-block w-100 slider_container"
                      src={slider4}
                      alt="Second slide"
                    />
                  </Carousel.Item>
                  {/* <Carousel.Item>
                    <img
                      className="d-block w-100 slider_container"
                      src={slider5}
                      alt="Second slide"
                    />
                  </Carousel.Item> */}
                  {/* <Carousel.Item>
                    <img
                      className="d-block w-100 slider_container"
                      src={slider6}
                      alt="Second slide"
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      className="d-block w-100 slider_container"
                      src={slider7}
                      alt="Second slide"
                    />
                  </Carousel.Item> */}
                </Carousel>
              </div>
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-2 mx-auto mt-0 blue_div"></div>
              <div className="col-lg-10 col-md-10 col-sm-10 col-xs-2 mx-auto main_page_detail_div">
                <p className="main_page_detail">
                  Welcome to 3xstock.com (http://3xstock.com/) - Your Trading
                  Intelligence Hub!
                </p>
                <p className="main_page_detail">
                  Access real-time market insights from Wall Street's top desks
                  (JPMorgan Chase, Goldman Sachs, Bank of America). Our proven
                  strategies turn the big data into profitable trading actions.
                  Get live trading news and tailored strategies for success.
                  Join us to master trading with confidence!
                </p>
                <br />
                <p
                  className="main_page_detail font-face-ka"
                  style={{ fontWeight: "550", fontSize: "22px" }}
                >
                  欢迎踏入3xstock.com (http://3xstock.com/) -
                  交易智慧的数字乐园！
                </p>
                <p
                  className="main_page_detail font-face-ka"
                  style={{ fontSize: "22px", fontWeight: "550" }}
                >
                  这里不仅是信息汇聚之地，更是您掌握交易艺术的AI数字助手。我们提供来自华尔街内部的最新实时交易信息和市场分析（如摩根大通、高盛、美国银行的Trading
                  Desk），以及量身定制的交易策略。将庞大的数据和信息转化为智能交易洞见，帮助您实现风险可控的交易决策，从而确保稳定的利润。加入我们的社区，共同探索交易智
                  慧，共同学习、共同赚钱，一同享受成功之旅！
                </p>
              </div>
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-2 mx-auto mt-5 mb-5 justify-content-center text-center">
                <Link to="/packages" className="btn subscribe-button ">
                  SUBSCRIBE
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Mainpage;
