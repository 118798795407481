// const BASE_URL = "https://api.stock.mindzbase.com/api/";
// const BASE_URL = "http://192.168.100.57:3000/api/";
const BASE_URL = "https://api.3xstock.com/api/";
module.exports = {
  SIGN_IN: BASE_URL + "auth/login",
  FILE_UPLOAD: BASE_URL + "file/upload-media",
  SEND_MESSAGE: BASE_URL + "message/send-message",
  GET_MESSAGE: BASE_URL + "message/getAll",
  ADD_GUEST: BASE_URL + "admin/addGuest",
  GET_ALL_USERS: BASE_URL + "admin/getAll",
  DELETE_USER: BASE_URL + "admin/delete",
  LOCK_Active: BASE_URL + "auth/register",
  FORGET_PASSWORD: BASE_URL + "auth/forgot",
  VALIDATE_OTP: BASE_URL + "auth/validateotp",
  UPDATE_PASSWORD: BASE_URL + "auth/updatepassword",
  UPDATE_PROFILE: BASE_URL + "admin/updateUser",
  SINGLE_USER: BASE_URL + "admin/getsingleUser",
  ADD_EDUCATION: BASE_URL + "education/addEducation",
  GET_EDUCATION: BASE_URL + "education/getEducation",
  GET_PACKAGE_DETAIL: BASE_URL + "product/getAll",
  GO_PAYPEL: BASE_URL + "checkout/add",
  GET_PAYMENT_DETAIL: BASE_URL + "checkout/payment-details",
  CANCEL_SUBSCRIPTION: BASE_URL + "checkout/cancel-payment",
  PAYMENT_LOGS: BASE_URL + "admin/getpaymentPlans",
  DELETE_MESSAGE: BASE_URL + "message/delete",
  CONTACT_US: BASE_URL + "contactUs",
  LOG_OUT: BASE_URL + "auth/logout",
};
