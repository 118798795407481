import React, { useEffect, useState } from "react";
import Layout from "../../Layout/Layout";
import "../CardPayment/CardPayment.css";
import master_card from "../../Assets/mastercard.png";
import logo_icon from "../../Assets/logo.png";

import { useNavigate } from "react-router-dom";

const CardPayment = () => {
  const navigate = useNavigate();

  const handlepayClick = () => {
    navigate("/Rescipt");
  };

  const [selectedPlanAmount, setSelectedPlanAmount] = useState("");
  const [selectedPlanName, setSelectedPlanName] = useState("");

  useEffect(() => {
    // Fetch values from local storage
    const storedAmount = localStorage.getItem("selectedPlanamount");
    const storedName = localStorage.getItem("selectedPlanname");

    // Update state variables
    setSelectedPlanAmount(storedAmount);
    setSelectedPlanName(storedName);

    console.log(storedAmount);
  }, []);

  return (
    <>
      <Layout>
        <div className="screen-container">
          <div className="row">
            <div className="col-12">
              <div className="row mt-5">
                <div className="col-lg-6 col-md-12 col-sm-12 col-xs-2 mx-auto mt-5 text-center">
                  <div className="payment_form_box ">
                    <p className="cardPayment_Headline mt-5">
                      Final step, complete your payment
                    </p>
                    <p className="text">
                      To start your subscription, input your card details to
                      make payment. You will be redirected to your banks
                      authorization page .
                    </p>
                    <form>
                      <div className="row">
                        <div className="col-12 d-flex justify-content-center align-items-center mt-5">
                          <input
                            type="text"
                            className="payment_input"
                            placeholder="Name on card"
                          />
                        </div>
                        <div className="input-with-logo-container col-12 d-flex mt-4 justify-content-center align-items-center mt-3">
                          <img
                            className="img_logo"
                            src={master_card}
                            alt="logo"
                          />
                          <input
                            type="text"
                            className="payment_input"
                            placeholder="Card Number"
                          />
                        </div>
                        <div className="input-with-logo-container col-6 d-flex mt-4 justify-content-center align-items-center mt-3">
                          <input
                            type="text"
                            className="payment_input_date"
                            placeholder="MM/YY"
                          />
                        </div>
                        <div className="input-with-logo-container col-6 d-flex mt-4 justify-content-center align-items-center mt-3">
                          <input
                            type="text"
                            className="payment_input_cvv"
                            placeholder="CVV"
                          />
                        </div>

                        <div className="input-with-logo-container col-12 d-flex mt-4 align-items-center mt-3">
                          <input type="checkbox" className="checkbox_payment" />
                          <label className="checkbox_labal">
                            Save Payment Method
                          </label>
                        </div>
                        <div className="input-with-logo-container col-12 d-flex mt-4 mb-5 align-items-center mt-3">
                          <button
                            type="submit"
                            className="payment_pay_btn"
                            onClick={handlepayClick}
                          >
                            Pay
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12 col-xs-2 mt-5 mx-auto">
                  <div className="payment_form_bill">
                    <div className="row">
                      <div className="col-12 d-flex">
                        <p className="bill_heading">You’re paying,</p>
                        <img
                          className="img_logo_bill"
                          src={logo_icon}
                          alt="logo"
                        />
                      </div>
                      <div className="col-12">
                        <p className="bill_amount">${selectedPlanAmount}</p>
                      </div>
                      <div className="col-6">
                        <p className="bill_sub_heading mt-4">
                          {selectedPlanName}
                        </p>
                      </div>
                      <div className="col-6">
                        <p className="bill_sub_heading_detail mt-4">
                          ${selectedPlanAmount}
                        </p>
                      </div>

                      <div className="col-12">
                        <hr className="mt-4 bill_hr" />
                      </div>
                      <div className="col-6 mt-4">
                        <p className="bill_sub_heading">Tax</p>
                      </div>
                      <div className="col-6 mt-4">
                        <p className="bill_sub_heading_detail">$ 50.00</p>
                      </div>
                      <div className="col-6 mt-4">
                        <p className="bill_sub_heading">Total</p>
                      </div>
                      <div className="col-6 mt-4">
                        <p className="bill_sub_heading_detail">$ 450.00</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default CardPayment;
