import React from "react";
// import logo_white from "../Assets/logoWhite.png";
import envelope_white from "../Assets/mailWhite.png";
import "../Footer/Footer.css";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <div className="screen-container-footer">
        <div className="row mt-3 footer_body">
          <div className="col-lg-4 col-md-4 col-sm-12 col-xs-2 d-flex text-center">
            <p className="leftParagraph">
              © 2024 3XStock All Rights Reserved |
              <Link className="leftlink" to="/PrivacyPolicy">
                Privacy Policy
              </Link>
            </p>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12 col-xs-2  mx-auto text-center d-flex">
            <Link to="https://softwarestudiousa.com/" className="center_text">
              Designed with Love by Software Studio USA
            </Link>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12 mx-auto d-flex col-xs-2 email_div text-center">
            <img src={envelope_white} alt="logo" className="envelop_img" />

            <p className="footer_mail">Email us at: help@3xstock.com</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
