import React, { useEffect, useState } from "react";
import Layout from "../../Layout/Layout";
// import profile_logo from "../../Assets/profilrlogo.png";
import leftArrow from "../../Assets/leftArrow.png";
import rightArrow from "../../Assets/rightArrow.png";
import "../Billing/Billing.css";
// import billing_card from "../../Assets/billingcard.png";

import jsPDF from "jspdf";
import "jspdf-autotable";

import { useNavigate } from "react-router-dom";
import { GET_PAYMENT_DETAIL } from "../../Common/Constants";

const Billing = () => {
  const [paymentData, setPaymentData] = useState([]);

  const navigate = useNavigate();

  const handleInvoiceDownload = () => {
    const pdf = new jsPDF();

    const topMargin = 20; // Adjust the top margin value as needed

    // Add content to the PDF
    pdf.text("Transaction History", 14, 15);

    // Convert the table to a PDF table with a top margin
    pdf.autoTable({ html: "#invoiceTable", startY: topMargin });

    // Save the PDF
    pdf.save("invoice.pdf");
  };

  const handleAccountClick = () => {
    // Navigate to the /billing route
    navigate("/Profile");
  };

  const handleSubscriptionPlanClick = () => {
    // Navigate to the /billing route
    navigate("/SubscriptionPlan");
  };

  const fetchData = async () => {
    try {
      const token = localStorage.getItem("globalToken");
      const userId = JSON.parse(localStorage.getItem("_id")); // Assuming "_id" is the key for the user ID in local storage
      console.log(
        `API URL is this: ${GET_PAYMENT_DETAIL}?isCurrent=false&userid=${userId}`
      );

      // Make the API call
      const response = await fetch(
        `${GET_PAYMENT_DETAIL}?isCurrent=false&userid=${userId}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.ok) {
        const data = await response.json();

        setPaymentData(data.data || []); // Ensure data.data is an array, or provide a default empty array

        // Log the API response data
        console.log("API Response for current false:", data.data);

        // Add any further processing based on the API response
      } else {
        // Handle errors for the API call
        console.error("Failed to fetch data:", response.statusText);
      }
    } catch (error) {
      // Handle network errors or other exceptions
      console.error("Error fetching data:", error.message);
    }
  };

  useEffect(() => {
    fetchData(); // Call the function on component mount

    // Add any dependencies to the dependency array if needed
  }, []);
  const formatDateString = (isoDateString) => {
    const date = new Date(isoDateString);
    return date.toISOString().split("T")[0]; // Extracting only the date part
  };

  return (
    <>
      <Layout>
        <div className="screen-container">
          <div className="row">
            <div className="col-12">
              <div className="row mt-5">
                <div className="col-lg-10 col-md-10 col-sm-10 col-xs-2 mx-auto text-center d-flex mb-5 center-content">
                  <img
                    className="img-fluid headingArrow"
                    src={rightArrow}
                    alt="logo"
                  />
                  <p className="everyHeading">Profile</p>
                  <img
                    className="img-fluid headingArrow"
                    src={leftArrow}
                    alt="logo"
                  />
                </div>
                <div className="col-lg-10 col-md-10 col-sm-10 col-xs-2 mx-auto margin_top d-flex">
                  <button className="inactive_btn" onClick={handleAccountClick}>
                    Profile
                  </button>
                  <button className="active_btn">Billing</button>
                  <button
                    className="inactive_btn"
                    onClick={handleSubscriptionPlanClick}
                  >
                    Subscription Plan
                  </button>
                </div>
                <div className="col-lg-10 col-md-10 col-sm-10 col-xs-2 mx-auto ">
                  <hr />
                </div>

                <div className="col-lg-10 col-md-10 col-sm-10 col-xs-4 mt-5 mx-auto d-flex justify-content-between">
                  <p className="transaction_history_heading">
                    Transaction history
                  </p>
                  <button
                    className="btn invoice_download_btn"
                    onClick={handleInvoiceDownload}
                  >
                    Invoice Download
                  </button>
                </div>
                <div className="col-lg-10 col-md-10 col-sm-10 col-xs-4 mt-5 mx-auto">
                  <table className="invoice_table" id="invoiceTable">
                    <thead className="invoice_table_heading">
                      <tr>
                        <th
                          style={{ textAlign: "left" }}
                          className="table_heading_size"
                        >
                          DATE
                        </th>
                        <th
                          style={{ textAlign: "center" }}
                          className="table_heading_size"
                        >
                          ORDER ID
                        </th>
                        <th
                          style={{ textAlign: "right" }}
                          className="table_heading_size"
                        >
                          TOTAL AMOUNT
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {paymentData.map((payment, index) => (
                        <tr key={index} className="billing_data">
                          <td style={{ textAlign: "left" }}>
                            {formatDateString(payment.createdAt)}
                          </td>

                          <td style={{ textAlign: "center" }}>
                            {payment.subscriptionId}
                          </td>
                          {/* <td
                            style={{ textAlign: "right" }}
                          >{`$${payment.amount.toFixed(2)}`}</td> */}
                          <td style={{ textAlign: "right" }}>
                            {`$${(payment.plan.amount ?? 0).toFixed(2)}`}
                          </td>
                        </tr>
                      ))}
                      {/* <tr className="billing_data">
                        <td style={{ textAlign: "left" }}>2023-11-23</td>
                        <td style={{ textAlign: "center" }}>12345</td>
                        <td style={{ textAlign: "right" }}>$100.00</td>
                      </tr>
                      <tr className="billing_data">
                        <td style={{ textAlign: "left" }}>2023-11-24</td>
                        <td style={{ textAlign: "center" }}>67890</td>
                        <td style={{ textAlign: "right" }}>$150.00</td>
                      </tr>
                      <tr className="billing_data">
                        <td style={{ textAlign: "left" }}>2023-11-25</td>
                        <td style={{ textAlign: "center" }}>54321</td>
                        <td style={{ textAlign: "right" }}>$80.00</td>
                      </tr> */}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default Billing;
