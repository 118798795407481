import React from "react";
import Layout from "../Layout/Layout";
// import disclaimerlogo from "../Assets/Disclaimerlogo.png";
import leftArrow from "../Assets/leftArrow.png";
import rightArrow from "../Assets/rightArrow.png";
import Disclaimer_img from "../Assets/slider3.jpg";

const Disclaimer = () => {
  return (
    <>
      <Layout>
        <div className="screen-container">
          <div className="row">
            <div className="col-12">
              <div className="row mt-5">
                <div className="col-lg-10 col-md-10 col-sm-10 col-xs-2 mx-auto text-center d-flex mb-5 center-content">
                  <img
                    className="img-fluid headingArrow"
                    src={rightArrow}
                    alt="logo"
                  />
                  <p className="everyHeading">Disclaimer</p>
                  <img
                    className="img-fluid headingArrow"
                    src={leftArrow}
                    alt="logo"
                  />
                </div>
                <div className="col-lg-7 col-md-7 col-sm-7 col-xs-2 mt-1 mb-5 mx-auto text-center">
                  <img
                    className="img-fluid  w-100 other_pages_img"
                    src={Disclaimer_img}
                    alt="logo"
                  />
                </div>
                <div className="col-lg-7 col-md-7 col-sm-7 mb-5 col-xs-2 mx-auto ">
                  <p
                    className="privacy_Detail"
                    style={{ textAlign: "justify", marginBottom: "4rem" }}
                  >
                    No Liability for Transaction Losses: 3xstock.com absolves
                    itself of any responsibility for losses resulting from
                    transactions conducted based on the information or analysis
                    presented on this website. Users assume full responsibility
                    for their trading or investment decisions when applying the
                    concepts presented in our materials. Illustrative Trading
                    Ideas: Specific trading ideas or strategies discussed in our
                    presentations or materials are for illustrative purposes
                    only and should not be interpreted as invitations for
                    transactions or recommendations to execute particular
                    trading strategies. They serve as examples to help you
                    understand various approaches to trading. Exercise Caution:
                    It is strongly advised that you exercise caution when
                    participating in stock or option transactions. We highly
                    recommend consulting your financial advisor for guidance on
                    investment, legal, or tax considerations related to options
                    transactions. Acknowledgment of Legal Terms: By using this
                    service, you acknowledge and agree to the legal terms
                    outlined above. 
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default Disclaimer;
