import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import logo_icon from "../../Assets/logo.png";
// import login_img from "../../Assets/login_img.png";
import back_img from "../../Assets/slider3.jpg";

import "./AdminLogin.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import { faLock } from "@fortawesome/free-solid-svg-icons";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { SIGN_IN } from "../../Common/Constants";

const Login = () => {
  const [inputtext, setinputtext] = useState({
    email: "",
    password: "",
  });
  const navigate = useNavigate();
  const [warnemail, setwarnemail] = useState(false);
  const [warnpassword, setwarnpassword] = useState(false);

  const [eye, seteye] = useState(true);
  const [password, setpassword] = useState("password");
  const [type, settype] = useState(false);

  const inputEvent = (event) => {
    const { name, value } = event.target;
    setinputtext((lastValue) => ({
      ...lastValue,
      [name]: value,
    }));

    // Reset warnings when input changes
    if (name === "email") {
      setwarnemail(false);
    } else if (name === "password") {
      setwarnpassword(false);
    }
  };

  const Eye = () => {
    if (password === "password") {
      setpassword("text");
      seteye(false);
      settype(true);
    } else {
      setpassword("password");
      seteye(true);
      settype(false);
    }
  };

  const handleLogin = async (e) => {
    e.preventDefault();

    const { email, password } = inputtext;
    if (!email || !password) {
      setwarnemail(!email);
      setwarnpassword(!password);
      return;
    }

    const payload = {
      email,
      password,
    };

    try {
      const response = await fetch(SIGN_IN, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        const data = await response.json();
        const token = data.token;

        localStorage.setItem("globalToken", token);

        const role = data.role;

        localStorage.setItem("role", JSON.stringify(data.role));

        // Check the role and navigate accordingly
        if (role === "admin") {
          navigate("/home");
        } else {
          // Clear localStorage for non-admin users
          localStorage.clear();
          // navigate("/");
          Swal.fire({
            title: "Error!",
            text: "Only admins can log in from here.",
            icon: "error",
            confirmButtonText: "OK",
            customClass: {
              confirmButton: "custom-confirm-button-class",
            },
          });
        }
        console.log("Login Successfully");
      } else {
        // Handle specific error cases, if needed
        // alert("Invalid email or password");
        const responseBody = await response.json(); // Parse JSON response
        const errorMessage =
          responseBody.message || "Failed to register. Please try again.";

        Swal.fire({
          title: "Error!",
          text: errorMessage,
          icon: "error",
          confirmButtonText: "OK",
          customClass: {
            confirmButton: "custom-confirm-button-class",
          },
        });
      }
    } catch (error) {
      console.error("Error during login:", error);
      Swal.fire({
        title: "Error!",
        text: "Network Error",
        icon: "error",
        confirmButtonText: "OK",
        customClass: {
          confirmButton: "custom-confirm-button-class",
        },
      });
    }
  };

  return (
    <>
      <div className="screen-container">
        <div className="img_div_back_ground">
          <div
            className="blur-background"
            style={{
              backgroundImage: `url(${back_img})`,
            }}
          />
          <div className="row ">
            {/* <div className="col-sm-12 col-lg-6 col-md-6 first_container">
            <div className="row">
              <div className="col-12">
                <Link to="/">
                  <img className="img_logo" src={logo_icon} alt="logo" />
                </Link>
              </div>
              <div className="col-12 login_img_div">
                <img className="login_img" src={login_img} alt="logo" />
              </div>
            </div>
          </div> */}
            <div className="col-sm-12 col-lg-12 col-md-12">
              {/* <div className="col-12 logo_display">
              <img className="img_logo" src={logo_icon} alt="logo" />
            </div> */}
              <div className="container">
                <div className="card">
                  <div className="text admin_heading">
                    {/* <h4 className="admin_h4">Admin</h4> */}
                    <Link to="/">
                      <img className="main_logo" src={logo_icon} alt="logo" />
                    </Link>
                  </div>
                  <div className="text login_text">
                    <h3>Admin Login</h3>
                  </div>
                  <form onSubmit={handleLogin}>
                    <div className="input-text left_right_margin">
                      <input
                        type="text"
                        className={` ${warnemail ? "warning" : ""}`}
                        placeholder="Enter your email"
                        value={inputtext.email}
                        onChange={inputEvent}
                        name="email"
                        style={{ backgroundColor: "#f4f4f4" }}
                      />
                      <FontAwesomeIcon
                        icon={faEnvelope}
                        className="fa-envelope"
                      />
                    </div>
                    <div className="input-text left_right_margin ">
                      <input
                        type={password}
                        className={`${warnpassword ? "warning" : ""} ${
                          type ? "type_password" : ""
                        }`}
                        placeholder="Enter your password"
                        value={inputtext.password}
                        onChange={inputEvent}
                        name="password"
                        style={{ backgroundColor: "#f4f4f4" }}
                      />
                      <FontAwesomeIcon icon={faLock} className="fa-lock" />

                      <FontAwesomeIcon
                        icon={eye ? faEye : faEyeSlash}
                        onClick={Eye}
                        className="eye-icon"
                      />
                    </div>
                    <div className="buttons left_right_margin">
                      <button type="submit" className="login_btn_style">
                        Login
                      </button>
                    </div>
                    <div className="Forget_password_div bottom_margin">
                      <Link
                        to="/ForgetPassword"
                        className="Forget_password_link"
                      >
                        Forget Password?
                      </Link>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
