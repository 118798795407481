import React, { useState, useEffect } from "react";
import Layout from "../../Layout/Layout";
import DatePicker from "react-datepicker";
import Swal from "sweetalert2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { Form, Modal, Button } from "react-bootstrap";

// import {
//   faLock,
//   faLockOpen,
//   faMagnifyingGlass,
//   faEllipsisVertical,
// } from "@fortawesome/free-solid-svg-icons";

import "react-datepicker/dist/react-datepicker.css";
import "../AdminHome/Home.css";
import {
  ADD_GUEST,
  DELETE_USER,
  GET_ALL_USERS,
  LOCK_Active,
} from "../../Common/Constants";

const Home = () => {
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [warnfName, setWarnfName] = useState(false);
  const [warnemail, setWarnemail] = useState(false);
  const [warnpassword, setWarnpassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [data, setData] = useState([]);
  const [tableData, setTableData] = useState([]);

  const [deleteItemId, setDeleteItemId] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const [lockItemId, setLockItemId] = useState(null);
  const [isLockModalOpen, setIsLockModalOpen] = useState(false);

  const [activeItemId, setActiveItemId] = useState(null);
  const [isActiveModalOpen, setIsActiveModalOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  const handleDeleteRow = (itemId) => {
    // Set the ID of the item to be deleted and open the modal
    setDeleteItemId(itemId);
    setIsDeleteModalOpen(true);
  };

  const handleLockRow = (itemId) => {
    // Set the ID of the item to be deleted and open the modal
    setLockItemId(itemId);
    setIsLockModalOpen(true);
  };

  const handleActiveRow = (itemId) => {
    // Set the ID of the item to be deleted and open the modal
    setActiveItemId(itemId);
    setIsActiveModalOpen(true);
  };

  const toggleModal = () => {
    // Toggle the modal state
    setIsDeleteModalOpen(!isDeleteModalOpen);
  };

  const toggleLockModal = () => {
    // Toggle the modal state
    setIsLockModalOpen(!isLockModalOpen);
  };

  const toggleActiveModal = () => {
    // Toggle the modal state
    setIsActiveModalOpen(!isActiveModalOpen);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleAddGuest = async () => {
    // Check if required fields are empty
    if (!name || !email || !password) {
      // alert("Please fill in all required fields.");
      Swal.fire({
        title: "Error!",
        text: "Please fill in all fields",
        icon: "error",
        confirmButtonText: "ok",
        customClass: {
          confirmButton: "custom-confirm-button-class",
        },
      });
      return;
    }

    // Validation for first name (only alphabets allowed)
    if (!/^[a-zA-Z]+$/.test(name)) {
      // alert("Invalid name. Please use only alphabetic characters.");
      Swal.fire({
        title: "Error!",
        text: "Invalid name. Please use only alphabetic characters.",
        icon: "error",
        confirmButtonText: "ok",
        customClass: {
          confirmButton: "custom-confirm-button-class",
        },
      });
      return;
    }

    // Validation for email using regular expression
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email.toLowerCase())) {
      // alert("Invalid email. Please enter a valid email address.");
      Swal.fire({
        title: "Error!",
        text: "Invalid email. Please enter a valid email address.",
        icon: "error",
        confirmButtonText: "ok",
        customClass: {
          confirmButton: "custom-confirm-button-class",
        },
      });
      return;
    }

    // Validation for password
    if (
      password.length < 8 ||
      !/\d/.test(password) ||
      !/[A-Z]/.test(password) ||
      !/[!@#$%^&*()_+{}[\]:;<>,.?~\\/-]/.test(password)
    ) {
      // alert(
      //   "Invalid password. Password must be at least 8 characters and contain at least one numeric character, one special character, and one capital letter."
      // );
      Swal.fire({
        title: "Error!",
        text: "Invalid password. Password must be at least 8 characters and contain at least one numeric character, one special character, and one capital letter.",
        icon: "error",
        confirmButtonText: "ok",
        customClass: {
          confirmButton: "custom-confirm-button-class",
        },
      });
      return;
    }

    // Assuming you have a state variable for the bearer token
    const token = localStorage.getItem("globalToken");
    const role = JSON.parse(localStorage.getItem("role"));
    if (!token) {
      console.error("Token not available");
      return;
    }

    // Prepare the payload
    const payload = {
      firstName: name,
      lastName: "",
      email,
      password,
      phone: "",
      status: "guest",
    };
    console.log("Guest Detail:", payload);

    try {
      const response = await fetch(ADD_GUEST, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          role: `${role}`,
          "Content-Type": "application/json",
          // Add any additional headers if needed
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        setName("");
        setEmail("");
        setPassword("");

        // alert("Guest added successfully!");
        // Use Toast instead of alert
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.onmouseenter = Swal.stopTimer;
            toast.onmouseleave = Swal.resumeTimer;
          },
        });
        Toast.fire({
          icon: "success",
          title: "Guest added successfully!",
        });
        // Handle success, e.g., close the modal
        handleClose(true);
        fetchData();
      } else {
        // Handle errors, you might want to show an error message
        console.error("Failed to add guest:", response.statusText);

        Swal.fire({
          title: "Error!",
          text: response.statusText,
          icon: "error",
          confirmButtonText: "OK",
          customClass: {
            confirmButton: "custom-confirm-button-class",
          },
        });
      }
    } catch (error) {
      console.error("Error adding guest:", error.message);

      Swal.fire({
        title: "Error!",
        text: "Network Error",
        icon: "error",
        confirmButtonText: "OK",
        customClass: {
          confirmButton: "custom-confirm-button-class",
        },
      });
    }
  };

  const handleConfirmDelete = async () => {
    const token = localStorage.getItem("globalToken");
    const role = JSON.parse(localStorage.getItem("role"));
    const apiUrl = `${DELETE_USER}?_id=${deleteItemId}`;
    console.log("+++++", role);
    console.log("______", token);
    console.log("Delete user:", apiUrl);
    try {
      // Make API call to delete item

      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          role: `${role}`,
          "Content-Type": "application/json",
          // Add headers if needed (e.g., authorization token)
        },
      });

      if (response.ok) {
        // If the API call is successful, update the state to re-render the component
        const updatedData = tableData.filter(
          (item) => item._id !== deleteItemId
        );
        setTableData(updatedData);
        console.log(`Item with _id ${deleteItemId} deleted successfully`);

        Swal.fire({
          position: "center",
          icon: "success",
          title: "User has been delete successfully",
          showConfirmButton: false,
          timer: 1500,
        });

        fetchData();
        setIsDeleteModalOpen(false);
      } else {
        console.error(`Failed to delete item with _id ${deleteItemId}`);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleConfrmActiveUser = async () => {
    const token = localStorage.getItem("globalToken");
    const role = JSON.parse(localStorage.getItem("role"));
    const apiUrl = `${LOCK_Active}?_id=${activeItemId}`;
    console.log("+++++", role);
    console.log("______", token);
    console.log("Lock user:", apiUrl);

    try {
      // Make API call to update user's lock status
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          role: `${role}`,
          "Content-Type": "application/json",
          // Add headers if needed (e.g., authorization token)
        },
        body: JSON.stringify({
          OperationMode: "UPDATE",
          isLock: false,
        }),
      });

      if (response.ok) {
        // If the API call is successful, update the state or perform other actions
        console.log(`User with _id ${activeItemId} locked successfully`);
        // Add any additional logic or state updates as needed
        Swal.fire({
          position: "center",
          icon: "success",
          title: "User has been active successfully",
          showConfirmButton: false,
          timer: 1500,
        });

        fetchData();
        setIsActiveModalOpen(false);
      } else {
        console.error(`Failed to lock user with _id ${activeItemId}`);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleConfrmLockUser = async () => {
    const token = localStorage.getItem("globalToken");
    const role = JSON.parse(localStorage.getItem("role"));
    const apiUrl = `${LOCK_Active}?_id=${lockItemId}`;
    console.log("+++++", role);
    console.log("______", token);
    console.log("Lock user:", apiUrl);

    try {
      // Make API call to update user's lock status
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          role: `${role}`,
          "Content-Type": "application/json",
          // Add headers if needed (e.g., authorization token)
        },
        body: JSON.stringify({
          OperationMode: "UPDATE",
          isLock: true,
        }),
      });

      if (response.ok) {
        // If the API call is successful, update the state or perform other actions
        console.log(`User with _id ${lockItemId} locked successfully`);
        // Add any additional logic or state updates as needed

        // Use Swal to show a success message
        Swal.fire({
          position: "center",
          icon: "success",
          title: "User has been locked successfully",
          showConfirmButton: false,
          timer: 1500,
        });

        fetchData();
        setIsLockModalOpen(false);
      } else {
        console.error(`Failed to lock user with _id ${lockItemId}`);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const formatDate = (date) => {
    const options = { year: "numeric", month: "short", day: "numeric" };
    return date.toLocaleDateString(undefined, options);
  };

  const handleInputChange = (event) => {
    const value = event.target.value;
    setSearchValue(value);
    fetchData(dateRange[0], dateRange[1], value); // Call fetchData with the input value
  };

  const fetchData = async (
    formattedStartDate,
    formattedEndDate,
    searchQuery
  ) => {
    const role = JSON.parse(localStorage.getItem("role"));

    try {
      // Check if formattedStartDate and formattedEndDate are not null or undefined
      const activeFromParam = formattedStartDate
        ? `activeFrom=${formattedStartDate}`
        : "";
      const activeToParam = formattedEndDate
        ? `activeTo=${formattedEndDate}`
        : "";

      // Construct the URL with query parameters based on whether they have values or not
      const url = `${GET_ALL_USERS}?${activeFromParam}${
        activeToParam && `&${activeToParam}`
      }${searchQuery ? `&searchKey=${searchQuery}` : ""}`;

      console.log(url);

      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("globalToken")}`,
          role: `${role}`,
          "Content-Type": "application/json",
          // Add any additional headers if needed
        },
      });

      if (response.ok) {
        const responseData = await response.json();
        setData(responseData.data);
        console.log("data is :", responseData.data);
      } else if (response.status === 403) {
        // Handle 403 Forbidden
        console.error("Access Forbidden. Redirecting to /mainpage");
        window.location.href = "/";
      } else {
        console.error("Failed to fetch data:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  useEffect(() => {
    // Call the fetch function
    fetchData();
  }, []); // Empty dependency array

  return (
    <>
      <Layout>
        <div className="screen-container">
          <div className="row">
            <div className="col-12">
              <div className="row mt-5">
                <div className="col-lg-10 col-md-10 col-sm-10 col-xs-2 mx-auto">
                  <div className="input-group">
                    <input
                      className="form-control search_bar"
                      type="text"
                      placeholder="Search By Name"
                      value={searchValue}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>

                <div className="col-lg-10 col-md-10 col-sm-10 col-xs-2 mx-auto mt-4 mb-4">
                  <div className="d-flex justify-content-end margin_right_date">
                    <button className="add_guest_btn " onClick={handleShow}>
                      Add Guest User
                    </button>

                    <DatePicker
                      className="Date_Range"
                      placeholderText="Select the Date Range"
                      selectsRange={true}
                      startDate={startDate}
                      endDate={endDate}
                      onChange={(update) => {
                        setDateRange((prevDateRange) => {
                          // Ensure that you are using the latest state
                          console.log("Start Date:", update[0]);
                          console.log("End Date:", update[1]);

                          // Extract only month, date, and year from the date object
                          const formattedStartDate = update[0]
                            ? formatDate(update[0])
                            : null;
                          const formattedEndDate = update[1]
                            ? formatDate(update[1])
                            : null;

                          console.log(
                            "Formatted Start Date:",
                            formattedStartDate
                          );
                          console.log("Formatted End Date:", formattedEndDate);

                          // Call fetchData with the latest state
                          fetchData(formattedStartDate, formattedEndDate);

                          // Return the updated state
                          return update;
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="col-lg-10 col-md-10 col-sm-10  mt-3 mx-auto table_div">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Phone</th>
                        <th>Joining Date</th>
                        <th>WeChat ID</th>
                        <th>Status</th>
                        <th>Level</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.map((item) => (
                        <tr
                          key={item._id}
                          style={{
                            background: item.isLock
                              ? "rgb(255, 186, 186) "
                              : "inherit",
                            color: item.isLock ? "black" : "inherit",
                          }}
                        >
                          <td
                            style={{
                              background: item.isLock
                                ? "rgb(255, 186, 186) "
                                : "inherit",
                            }}
                          >
                            {item.userId}
                          </td>
                          <td
                            style={{
                              background: item.isLock
                                ? "rgb(255, 186, 186) "
                                : "inherit",
                            }}
                          >{`${item.firstName} ${item.lastName}`}</td>
                          <td
                            style={{
                              background: item.isLock
                                ? "rgb(255, 186, 186) "
                                : "inherit",
                            }}
                          >
                            {item.email}
                          </td>
                          <td
                            style={{
                              background: item.isLock
                                ? "rgb(255, 186, 186) "
                                : "inherit",
                            }}
                          >
                            {item.phone || "N/A"}
                          </td>
                          <td
                            style={{
                              background: item.isLock
                                ? "rgb(255, 186, 186) "
                                : "inherit",
                            }}
                          >
                            {new Date(item.createdAt).toLocaleString([], {
                              year: "numeric",
                              month: "short",
                              day: "numeric",
                            })}
                          </td>
                          <td
                            style={{
                              background: item.isLock
                                ? "rgb(255, 186, 186) "
                                : "inherit",
                            }}
                          >
                            {item.weChatId || "N/A"}
                          </td>
                          <td
                            className={
                              item.status === "unsubscribed"
                                ? "Unsubscribed"
                                : "Subscribed"
                            }
                          >
                            {item.status}
                          </td>
                          <td
                            className={
                              item.level === "normal" ? "normal" : "vip"
                            }
                          >
                            {item.level}
                          </td>
                          <td>
                            <div
                              className="btn-group"
                              role="group"
                              aria-label="Basic mixed styles example"
                            >
                              <button
                                type="button"
                                // className="btn btn-outline-success"
                                className={`btn ${
                                  item.isLock
                                    ? "btn-outline-success"
                                    : "btn-success"
                                }`}
                                style={{
                                  fontFamily: "Patua One",
                                  fontSize: "14px",
                                }}
                                onClick={() => handleActiveRow(item._id)}
                              >
                                Active
                              </button>
                              <button
                                type="button"
                                // className="btn btn-outline-primary"
                                className={`btn ${
                                  item.isLock
                                    ? "btn-primary"
                                    : "btn-outline-primary"
                                }`}
                                style={{
                                  fontFamily: "Patua One",
                                  fontSize: "14px",
                                }}
                                onClick={() => handleLockRow(item._id)}
                              >
                                Lock
                              </button>
                              <button
                                type="button"
                                className="btn btn-outline-danger"
                                onClick={() => handleDeleteRow(item._id)}
                                style={{
                                  fontFamily: "Patua One",
                                  fontSize: "14px",
                                }}
                              >
                                Delete
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  {/* DELETE MODAL */}
                  <Modal show={isDeleteModalOpen} onHide={toggleModal} centered>
                    <Modal.Header>
                      <Modal.Title className="text-center justify-content-center align-items-center text-align-center ">
                        <h2 className="ml-3">Are you sure?</h2>
                      </Modal.Title>
                    </Modal.Header>

                    <Modal.Body className="text-center">
                      <h5>
                        Do you really want to delete this user? This process
                        cannot be undone.
                      </h5>
                    </Modal.Body>

                    <Modal.Footer className="text-center">
                      <Button variant="secondary" onClick={toggleModal}>
                        Cancel
                      </Button>
                      <Button variant="danger" onClick={handleConfirmDelete}>
                        Delete
                      </Button>
                    </Modal.Footer>
                  </Modal>

                  {/* LOCK MODAL */}
                  <Modal
                    show={isLockModalOpen}
                    onHide={toggleLockModal}
                    centered
                  >
                    <Modal.Header>
                      <Modal.Title className="text-center justify-content-center align-items-center">
                        <h2 className="ml-3">Are you sure?</h2>
                      </Modal.Title>
                    </Modal.Header>

                    <Modal.Body className="text-center">
                      <h5>Do you really want to Lock this user?</h5>
                    </Modal.Body>

                    <Modal.Footer className="text-center">
                      <Button variant="secondary" onClick={toggleLockModal}>
                        Cancel
                      </Button>
                      <Button variant="primary" onClick={handleConfrmLockUser}>
                        Lock
                      </Button>
                    </Modal.Footer>
                  </Modal>

                  {/* Active Modal */}
                  <Modal
                    show={isActiveModalOpen}
                    onHide={toggleActiveModal}
                    centered
                  >
                    <Modal.Header>
                      <Modal.Title className="text-center justify-content-center align-items-center">
                        <h2 className="ml-3">Are you sure?</h2>
                      </Modal.Title>
                    </Modal.Header>

                    <Modal.Body className="text-center">
                      <h5>Do you really want to Active this user?</h5>
                    </Modal.Body>

                    <Modal.Footer className="text-center">
                      <Button variant="secondary" onClick={toggleActiveModal}>
                        Cancel
                      </Button>
                      <Button
                        variant="success"
                        onClick={handleConfrmActiveUser}
                      >
                        Active
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Modal to show add guest */}
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton className="guest_modal">
            <Modal.Title>Add New Guest</Modal.Title>
          </Modal.Header>
          <Modal.Body className="guest_modal">
            <Form>
              <Form.Group className="mb-3" controlId="add-guest-control">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  autoFocus
                  className={` ${warnfName ? "warning" : ""}`}
                  style={{ backgroundColor: "#f4f4f4" }}
                />
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="text"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  autoFocus
                  className={` ${warnemail ? "warning" : ""}`}
                  style={{ backgroundColor: "#f4f4f4" }}
                />
                <Form.Label>Password</Form.Label>
                <div className="password-input-container">
                  <Form.Control
                    type={showPassword ? "text" : "password"}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    autoFocus
                    className={` ${warnpassword ? "warning" : ""}`}
                    style={{ backgroundColor: "#f4f4f4" }}
                  />
                  <div
                    className="password-toggle"
                    onClick={togglePasswordVisibility}
                  >
                    <FontAwesomeIcon
                      icon={showPassword ? faEyeSlash : faEye}
                      className="guest-eye-icon"
                    />
                  </div>
                </div>
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer className="justify-content-center guest_modal">
            <div className="d-grid gap-2 guest_modal">
              <Button
                className="addNewGuest"
                onClick={handleAddGuest}
                size="lg"
              >
                Add New Member
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      </Layout>
    </>
  );
};

export default Home;
