import "./App.css";
import React from "react";
import AppRouter from "./Components/Router/Router";
import "bootstrap/dist/css/bootstrap.min.css";
import "typeface-montserrat";

import { Helmet } from "react-helmet";

function App() {
  return (
    <React.StrictMode>
      <Helmet>
        <title>3X Stocks</title>
        <link rel="icon" type="image/png" href="/logo192.png" />
      </Helmet>
      <AppRouter />
    </React.StrictMode>
  );
}

export default App;
