import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";

import logo_icon from "../../Assets/logo.png";
// import login_img from "../../Assets/login_img.png";
import back_img from "../../Assets/slider3.jpg";

import "../UserSignUp/UserSignUp.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faEnvelope,
  faComment,
} from "@fortawesome/free-regular-svg-icons";
import { faLock, faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { LOCK_Active } from "../../Common/Constants";

const UserSignUp = () => {
  const [inputtext, setinputtext] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    WeChatId: "",
  });
  const [phone, setPhone] = useState("");
  const navigate = useNavigate();
  const [warnfName, setWarnfName] = useState(false);
  const [warnemail, setwarnemail] = useState(false);
  const [warnpassword, setwarnpassword] = useState(false);

  const [eye, seteye] = useState(true);
  const [password, setpassword] = useState("password");
  const [type, settype] = useState(false);

  const inputEvent = (event) => {
    const { name, value } = event.target;

    // Handle backspace separately
    if (event.nativeEvent.inputType === "deleteContentBackward") {
      setinputtext((lastValue) => ({
        ...lastValue,
        [name]: value,
      }));
      return;
    }

    // Validation for first name and last name (only alphabets allowed)
    if (name === "firstName" || name === "lastName") {
      if (!/^[a-zA-Z]+$/.test(value)) {
        // If input contains non-alphabetic characters, do not update state
        return;
      }
    }

    setinputtext((lastValue) => ({
      ...lastValue,
      [name]: value,
    }));

    // Reset warnings when input changes

    if (name === "firstName") {
      setWarnfName(false);
    } else if (name === "email") {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(value.toLowerCase())) {
        setwarnemail(false);
        return;
      }
      // setwarnemail(false);
    } else if (name === "password") {
      if (
        value.length < 8 ||
        !/\d/.test(value) ||
        !/[A-Z]/.test(value) ||
        !/[!@#$%^&*()_+{}[\]:;<>,.?~\\/-]/.test(value)
      ) {
        setwarnpassword(false);
        return;
      }
      // setwarnpassword(false);
    }
  };

  const Eye = () => {
    if (password === "password") {
      setpassword("text");
      seteye(false);
      settype(true);
    } else {
      setpassword("password");
      seteye(true);
      settype(false);
    }
  };

  const SignUp = async (e) => {
    e.preventDefault();

    // Check if required fields are empty
    if (!inputtext.firstName || !inputtext.email || !inputtext.password) {
      // Set a warning or handle the error as needed
      // alert("Please fill in all required fields");
      Swal.fire({
        title: "Error!",
        text: "Please fill in all required fields",
        icon: "error",
        confirmButtonText: "ok",
        customClass: {
          confirmButton: "custom-confirm-button-class",
        },
      });
      // For example, you can set state variables to indicate warnings
      setWarnfName(!inputtext.firstName);
      setwarnemail(!inputtext.email);
      setwarnpassword(!inputtext.password);
      return;
    }

    // Email validation using a regular expression
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(inputtext.email.toLowerCase())) {
      // Display an alert for invalid email
      // alert("Invalid email. Please enter a valid email address");
      Swal.fire({
        title: "Error!",
        text: "Invalid email. Please enter a valid email address",
        icon: "error",
        confirmButtonText: "ok",
        customClass: {
          confirmButton: "custom-confirm-button-class",
        },
      });
      setwarnemail(true); // Set warning for email
      return;
    }

    if (
      inputtext.password.length < 8 ||
      !/\d/.test(inputtext.password) ||
      !/[A-Z]/.test(inputtext.password) ||
      !/[!@#$%^&*()_+{}[\]:;<>,.?~\\/-]/.test(inputtext.password)
    ) {
      // Set a warning or handle the error as needed
      // alert(
      //   "Password must be at least 8 characters and contain at least one numeric character and one special character and one capital letter"
      // );
      Swal.fire({
        title: "Error!",
        text: "Password must be at least 8 characters and contain at least one numeric character and one special character and one capital letter",
        icon: "error",
        confirmButtonText: "ok",
        customClass: {
          confirmButton: "custom-confirm-button-class",
        },
      });
      setwarnpassword(!inputtext.password);
      return;
    }

    // Check if 'inputtext.phone' is defined and its length is less than 11
    const phone =
      inputtext.phone && inputtext.phone.length < 11 ? "" : inputtext.phone;

    // Construct the payload
    const payload = {
      firstName: inputtext.firstName,
      lastName: inputtext.lastName,
      email: inputtext.email,
      password: inputtext.password,
      phone: phone,
      weChatID: inputtext.WeChatId,
    };

    // Log the payload
    console.log("User Data:", payload);

    // Assuming you have a state variable for the bearer token
    const token = localStorage.getItem("globalToken");
    const role = JSON.parse(localStorage.getItem("role"));

    try {
      const response = await fetch(LOCK_Active, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          role: `${role}`,
          "Content-Type": "application/json",
          // Add any additional headers if needed
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        // Clear form data or perform other actions on success

        setinputtext({
          firstName: "",
          lastName: "",
          email: "",
          password: "",
          WeChatId: "",
        });
        setPhone("");

        // Show a success toast message
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.onmouseenter = Swal.stopTimer;
            toast.onmouseleave = Swal.resumeTimer;
          },
        });
        Toast.fire({
          icon: "success",
          title: "Registered successfully",
        });

        // alert("Register Successfully");
        // Handle success, e.g., show a success message
        navigate("/UserLogin");
        console.log("User added successfully!");
      } else {
        // Handle errors, you might want to show an error message
        const responseBody = await response.json(); // Parse JSON response
        const errorMessage =
          responseBody.message || "Failed to register. Please try again.";

        Swal.fire({
          title: "Error!",
          text: errorMessage,
          icon: "error",
          confirmButtonText: "OK",
          customClass: {
            confirmButton: "custom-confirm-button-class",
          },
        });
      }
    } catch (error) {
      console.error("Error adding user:", error.message);
      Swal.fire({
        title: "Error!",
        text: "Network Error",
        icon: "error",
        confirmButtonText: "OK",
        customClass: {
          confirmButton: "custom-confirm-button-class",
        },
      });
    }
  };

  return (
    <>
      <div className="screen-container">
        <div className="img_div_back_ground">
          <div
            className="blur-background"
            style={{
              backgroundImage: `url(${back_img})`,
            }}
          />
          <div className="row ">
            {/* <div className="col-sm-12 col-lg-6 col-md-6 first_container">
            <div className="row">
              <div className="col-12">
                <Link to="/">
                  <img className="img_logo" src={logo_icon} alt="logo" />
                </Link>
              </div>
              <div className="col-12 login_img_div">
                <img className="login_img" src={login_img} alt="logo" />
              </div>
            </div>
          </div> */}
            <div className="col-sm-12 col-lg-12 col-md-12">
              {/* <div className="col-12 logo_display">
              <img className="img_logo" src={logo_icon} alt="logo" />
            </div> */}
              <div className="container">
                <div className="card" style={{ width: "550px" }}>
                  <div className="text admin_heading">
                    {/* <h4 className="admin_h4">User</h4> */}
                    <Link to="/">
                      <img className="main_logo" src={logo_icon} alt="logo" />
                    </Link>
                  </div>
                  <div className="text login_text">
                    <h3>User Sign Up</h3>
                  </div>
                  <form onSubmit={SignUp}>
                    <div className="input-text left_right_margin  needs-validation">
                      <div className="row">
                        <div className="col-6">
                          <input
                            type="text"
                            className={` ${warnfName ? "warning" : ""}`}
                            placeholder=" First Name"
                            // id="validationCustom01"
                            value={inputtext.firstName}
                            onChange={inputEvent}
                            name="firstName"
                            style={{ backgroundColor: "#f4f4f4" }}
                            required
                          />
                          <FontAwesomeIcon icon={faUser} className="fa-user" />
                        </div>
                        <div className="col-6">
                          <input
                            type="text"
                            className=""
                            placeholder=" Last Name"
                            value={inputtext.lastName}
                            onChange={inputEvent}
                            name="lastName"
                            style={{ backgroundColor: "#f4f4f4" }}
                          />
                          <FontAwesomeIcon icon={faUser} className="fa-user1" />
                        </div>
                        <div className="col-6 mt-3">
                          <input
                            type="text"
                            className={` ${warnemail ? "warning" : ""}`}
                            placeholder="Enter your email"
                            value={inputtext.email}
                            onChange={inputEvent}
                            name="email"
                            style={{ backgroundColor: "#f4f4f4" }}
                          />
                          <FontAwesomeIcon
                            icon={faEnvelope}
                            className="fa-envelope1"
                          />
                        </div>
                        <div className="col-6 mt-3">
                          <PhoneInput
                            inputStyle={{
                              width: "100%",
                              height: "45px",
                              borderRadius: "8px",
                              background: "#f4f4f4",
                            }}
                            country={"us"}
                            enableSearch={true}
                            value={phone}
                            onChange={(phone) => setPhone(phone)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="input-text left_right_margin">
                      <input
                        type={password}
                        className={`form-control ${
                          warnpassword ? "warning" : ""
                        } ${type ? "type_password" : ""}`}
                        placeholder="Password"
                        value={inputtext.password}
                        onChange={inputEvent}
                        name="password"
                        style={{ backgroundColor: "#f4f4f4" }}
                        // required
                      />

                      <FontAwesomeIcon icon={faLock} className="fa-lock" />

                      <FontAwesomeIcon
                        icon={eye ? faEye : faEyeSlash}
                        onClick={Eye}
                        className="eye-icon"
                      />
                    </div>
                    <div className="input-text left_right_margin">
                      <input
                        type="text"
                        className=""
                        placeholder=" WeChat Id"
                        value={inputtext.WeChatId}
                        onChange={inputEvent}
                        name="WeChatId"
                        style={{ backgroundColor: "#f4f4f4" }}
                      />
                      <FontAwesomeIcon
                        icon={faComment}
                        className="fa-envelope"
                      />
                    </div>
                    <div className="buttons left_right_margin mt-3">
                      <button type="submit" className="login_btn_style">
                        Register
                      </button>
                    </div>
                  </form>

                  <div className="Forget_password_div">
                    <p>
                      Already have account?
                      <Link
                        to="/UserLogin                                                                          "
                        className="signUp_link"
                      >
                        Sign In
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserSignUp;
