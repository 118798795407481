import React from "react";
// import Modal from "react-bootstrap/Modal";
// import { Modal, Button } from "react-bootstrap";

import { Link } from "react-router-dom";
import Swal from "sweetalert2";

import Layout from "../../Layout/Layout";
import "../Packages/Packages.css";
// import packagesLogo from "../../Assets/packageslogo.png";
import leftArrow from "../../Assets/leftArrow.png";
import rightArrow from "../../Assets/rightArrow.png";

// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { GET_PACKAGE_DETAIL, GO_PAYPEL } from "../../Common/Constants";

const Packages = () => {
  const handleChoosePlan = async (plan) => {
    try {
      // Check if the user is not logged in
      if (!localStorage.getItem("globalToken")) {
        // If not logged in, navigate to "/UserLogin"
        // You should replace this with your actual navigation logic
        window.location.href = "/UserLogin";
        return; // Stop further processing
      }

      const token = localStorage.getItem("globalToken");
      const userId = JSON.parse(localStorage.getItem("_id")); // Assuming "_id" is the key for the user ID in local storage

      // Make the API call with the selected plan
      const response = await fetch(`${GET_PACKAGE_DETAIL}?plan=${plan}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      if (response.ok) {
        const data = await response.json();
        // Log the API response data
        console.log("API Response is that:", data);

        // Save the planId in local storage
        const planId = data.data[0].planId;
        console.log("+++++", planId);
        // localStorage.setItem("selectedPlanId", planId);

        // Make a second API call with the obtained planId
        const checkoutResponse = await fetch(GO_PAYPEL, {
          method: "POST", // Adjust the method based on your API
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },

          body: JSON.stringify({
            planId: planId,
            userId: userId,
            // Add any other required parameters
          }),
        });

        if (checkoutResponse.ok) {
          const checkoutData = await checkoutResponse.json();

          console.log("+++++++____: ", checkoutData.responseMessage);
          console.log(
            "Checkout API Response is that:",
            checkoutData.links[0].href
          );
          // Check if the browser is Safari
          const isSafari = /^((?!chrome|android).)*safari/i.test(
            navigator.userAgent
          );

          // Ask for permission if the browser is Safari
          if (isSafari) {
            Swal.fire({
              title: "Alert",
              text: "Allow opening in a new tab?",
              icon: "info",
              showCancelButton: true,
              confirmButtonText: "Yes",
              cancelButtonText: "No",
              customClass: {
                confirmButton: "custom-confirm-button-class",
              },
            }).then((result) => {
              if (result.isConfirmed) {
                // User granted permission, open in a new tab
                window.open(checkoutData.links[0].href, "_blank");
                Swal.fire({
                  title: "Alert",
                  text: checkoutData.responseMessage,
                  icon: "success",
                  confirmButtonText: "OK",
                  customClass: {
                    confirmButton: "custom-confirm-button-class",
                  },
                });
              } else {
                // Handle user denial
              }
            });
          } else {
            // Open the new tab for non-Safari browsers
            window.open(checkoutData.links[0].href, "_blank");
            Swal.fire({
              title: "Alert",
              text: checkoutData.responseMessage,
              icon: "success",
              confirmButtonText: "OK",
              customClass: {
                confirmButton: "custom-confirm-button-class",
              },
            });
          }
        } else {
          // Handle errors for the second API call
          console.error("Failed to checkout:", checkoutResponse.statusText);
          // alert("You are already subscribed");
          Swal.fire({
            title: "Error!",
            text: "You are already subscribed",
            icon: "info",
            confirmButtonText: "OK",
            customClass: {
              confirmButton: "custom-confirm-button-class",
            },
          });
        }

        // navigate("/CardPayment");
      } else {
        // Handle errors for the first API call
        console.error("Failed to fetch data:", response.statusText);
        Swal.fire({
          title: "Error!",
          text: "Network Error",
          icon: "error",
          confirmButtonText: "OK",
          customClass: {
            confirmButton: "custom-confirm-button-class",
          },
        });
      }
    } catch (error) {
      // Handle network errors or other exceptions
      console.error("Error fetching data:", error.message);
      Swal.fire({
        title: "Error!",
        text: "Network Error",
        icon: "error",
        confirmButtonText: "OK",
        customClass: {
          confirmButton: "custom-confirm-button-class",
        },
      });
    }
  };

  return (
    <>
      <Layout>
        <div className="screen-container">
          <div className="row mb-5">
            <div className="col-12 mb-5">
              <div className="row mt-5 mb-5">
                <div className="col-lg-10 col-md-10 col-sm-10 col-xs-2 mx-auto text-center d-flex mb-5 center-content">
                  <img
                    className="img-fluid headingArrow"
                    src={rightArrow}
                    alt="logo"
                  />
                  <p className="everyHeading">Pricing Plan</p>
                  <img
                    className="img-fluid headingArrow"
                    src={leftArrow}
                    alt="logo"
                  />
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 col-xs-2 mt-5 mx-auto mb-5 text-center d-flex justify-content-center">
                  <div className="package_one_div text-center justify-content-center align-items-center">
                    <p className="package_first_line">Recommended for you</p>
                    <hr className="package_hr" />
                    <p className="plan_name_packages">Monthly Plan</p>
                    <p className="plan_duration">
                      Active with high exposure for 30 days
                    </p>
                    <p className="package_price">$48.88*</p>
                    <Link
                      to="#"
                      className="btn chose_plan_btn"
                      onClick={() => handleChoosePlan("Monthly Plan")}
                    >
                      Choose Plan
                    </Link>
                    <div className="text-center d-flex justify-content-center mt-4">
                      <p className="plan_duration" style={{ color: "#fff" }}>
                        Active for 30 days
                      </p>
                    </div>
                  </div>
                </div>
                {/* <button onClick={openModal}>Open</button> */}
                <div className="col-lg-6 col-md-6 col-sm-12 col-xs-2 mt-5 mx-auto mb-5 text-center d-flex justify-content-center">
                  <div className="package_two_div text-center justify-content-center align-items-center">
                    <p className="package_first_line">Recommended for you</p>
                    <hr className="package_hr" />
                    <p className="plan_name_packages">Yearly plan</p>
                    <p className="plan_duration">
                      Active with high exposure for 365 days
                    </p>
                    <p className="package_price">$437.68*</p>
                    <Link
                      to="#"
                      className="btn chose_plan_btn"
                      onClick={() => handleChoosePlan("Yearly Plan")}
                    >
                      Choose Plan
                    </Link>
                    <div className="text-center d-flex justify-content-center mt-4">
                      {/* <FontAwesomeIcon
                        icon={faCheck}
                        className="mt-1 package_tick"
                        style={{ color: "#fff", marginRight: "10px" }}
                      /> */}
                      <p className="plan_duration">Active for 365 days</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default Packages;
