import React, { useState, useEffect, useRef } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import "../MarketAnalysis/MarketAnalysis.css";
import Layout from "../../Layout/Layout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  // faTrashAlt,
  faPaperclip,
  faPaperPlane,
  faTimes,
  // faLock,
  faPen,
} from "@fortawesome/free-solid-svg-icons";
import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import imageUrl from "../../Assets/processing.gif";

import DailyReadingimg from "../../Assets/dailyreadingimg.png";

import {
  DELETE_MESSAGE,
  FILE_UPLOAD,
  GET_MESSAGE,
  SEND_MESSAGE,
} from "../../Common/Constants";

function DailyReading() {
  const role = JSON.parse(localStorage.getItem("role"));

  const isAdmin = role === "admin";

  const [messages, setMessages] = useState([]);
  const [inputText, setInputText] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);

  const [showModal, setShowModal] = useState(false);
  // const [selectedImage, setSelectedImage] = useState(null);
  const [selectedMedia, setSelectedMedia] = useState(null);

  const handleMediaClick = (mediaUrl, mediaExtension) => {
    setSelectedMedia({ url: mediaUrl, extension: mediaExtension });
    setShowModal(true);
  };

  const [show, setShow] = useState(false);
  const [filePreview, setFilePreview] = useState(null);
  const [isDragging, setIsDragging] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleEdit = (index) => {
    const editedItem = messages[index];

    if (!editedItem.message.trim()) {
      // If the message is empty or contains only whitespace
      // alert("This message is not editable");
      Swal.fire({
        title: "Error!",
        text: "This message is not editable",
        icon: "error",
        confirmButtonText: "OK",
        customClass: {
          confirmButton: "custom-confirm-button-class",
        },
      });
    } else {
      setInputText(editedItem.message); // Set the inputText with the edited message
      // Set the _id in localStorage
      localStorage.setItem("editedMessageId", editedItem._id);
      // Add any other logic you want to perform on edit
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    // setSelectedImage(null);
  };

  const handleFileInputChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setFilePreview(reader.result);
      };
      reader.readAsDataURL(file);

      uploadfile(file);
    }
    // Reset the input value to trigger onChange even if the same file is selected again
    event.target.value = "";
  };

  const getFileType = () => {
    if (!selectedFile) {
      return null;
    }

    const fileType = selectedFile.type;
    if (fileType.startsWith("image/")) {
      return "image";
    } else if (fileType.startsWith("video/")) {
      return "video";
    } else if (fileType.startsWith("audio/")) {
      return "audio";
    } else if (fileType === "application/pdf") {
      return "pdf";
    }

    return null;
  };

  const handleDeleteFile = () => {
    // Clear the file preview and selected file
    setFilePreview(null);
    setSelectedFile(null);
  };

  const uploadfile = async (file) => {
    try {
      handleShow();
      const token = localStorage.getItem("globalToken");
      const role = JSON.parse(localStorage.getItem("role"));
      const headers = {
        Authorization: `Bearer ${token}`,
        role: `${role}`,
      };

      // Get the extension from the file name
      const extension = file.name.split(".").pop();
      localStorage.setItem("fileExtension", extension);

      // Use the extension as the objectKey and update the formData
      const formData = new FormData();
      formData.append("file", file);
      formData.append("objectKey", "DailyReading/" + extension);

      const response = await fetch(FILE_UPLOAD, {
        method: "POST",
        headers: headers,
        body: formData,
      });

      const fileUploadResponse = await response.json();

      console.log("Response data:", fileUploadResponse);
      handleClose();
      scrollToBottom();
      // Return the fileUploadResponse to get the signedUrl
      return fileUploadResponse;
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  const handleDelete = async (index) => {
    // Retrieve the message to be deleted
    const deletedMessage = messages[index];

    // Log information about the specific message
    console.log("Deleting message ID:", deletedMessage._id);

    // Show the Swal confirmation dialog
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        // If the user clicks "Yes", proceed with the deletion

        // Construct the API endpoint with the messageId as a query parameter
        const apiEndpoint = `${DELETE_MESSAGE}?messageId=${deletedMessage._id}`;

        try {
          const token = localStorage.getItem("globalToken");
          const role = JSON.parse(localStorage.getItem("role"));
          if (!token) {
            console.error("Token not available");
            return;
          }

          // Make the API call to delete the message
          const response = await fetch(apiEndpoint, {
            method: "POST", // or the appropriate HTTP method for your API
            headers: {
              Authorization: `Bearer ${token}`,
              role: `${role}`,
              "Content-Type": "application/json",
              // Include any headers required for your API
              // For example, Authorization header if needed
            },
          });

          if (response.ok) {
            // Handle success, e.g., update the UI or perform additional actions
            console.log("Message deleted successfully");

            // Update messages by removing the message at the specified index
            setMessages((prevMessages) => [
              ...prevMessages.slice(0, index),
              ...prevMessages.slice(index + 1),
            ]);

            // Show success notification
            Swal.fire({
              title: "Deleted!",
              text: "Your message has been deleted.",
              icon: "success",
              confirmButtonText: "ok",
              customClass: {
                confirmButton: "custom-confirm-button-class",
              },
            });
          } else {
            // Handle errors, you might want to show an error message
            console.error("Failed to delete message:", response.statusText);

            // Show error notification
            Swal.fire({
              title: "Error",
              text: "Failed to delete message. Please try again.",
              icon: "error",
            });
          }
        } catch (error) {
          // Handle errors from the fetch operation
          console.error("Error deleting message:", error.message);

          // Show error notification
          Swal.fire({
            title: "Error",
            text: "An unexpected error occurred. Please try again.",
            icon: "error",
          });
        }
      }
      // If the user clicks "No" or closes the dialog, do nothing
    });
  };

  const handleSubmit = (event) => {
    // Prevent the default form submission behavior
    event.preventDefault();
    // Call the handleButtonClick function
    // handleButtonClick();
  };

  const handleButtonClick = async () => {
    try {
      let signedUrl = null;
      handleShow();
      // Call the uploadfile function to get the signedUrl
      const file = selectedFile;
      if (file) {
        const fileUploadResponse = await uploadfile(file);
        signedUrl = fileUploadResponse.signedUrl;
      }

      // Check if either inputText or signedUrl is present
      if (!inputText && !signedUrl) {
        console.error("Either inputText or signedUrl is required");
        return;
      }

      const extension = localStorage.getItem("fileExtension");

      const payload = {
        sender: "admin",
        message: inputText || "",
        receiver: "guest",
        subject: "DailyReading",
        mediaUrl: signedUrl || "",
        mediaExtension: extension || "",
      };
      // Check if there is an edited message ID in localStorage
      const editedMessageId = localStorage.getItem("editedMessageId");
      if (editedMessageId) {
        // Include the _id in the payload
        payload._id = editedMessageId;
      }

      console.log("payload:", payload);

      const token = localStorage.getItem("globalToken");
      const role = JSON.parse(localStorage.getItem("role"));

      const headers = {
        Authorization: `Bearer ${token}`,
        role: `${role}`,
        "Content-Type": "application/json",
      };

      // Perform the action using the payload (send it to your backend, for example)
      const response = await fetch(SEND_MESSAGE, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        // Handle success if needed
        console.log("Message sent successfully");
        localStorage.removeItem("fileExtension");
        // Clear the editedMessageId from localStorage after sending
        localStorage.removeItem("editedMessageId");
      } else {
        // Handle error if needed
        console.error("Error sending message:", response.statusText);
      }

      // Clear the input text and selected file after sending
      setInputText("");
      setSelectedFile(null);
      setFilePreview(null);
      setSelectedFile(null);
      fetchMessages();
    } catch (error) {
      console.error("Error handling button click:", error);
    }
  };

  // Fetch all the messages
  const fetchMessages = async () => {
    try {
      const token = localStorage.getItem("globalToken");
      const role = JSON.parse(localStorage.getItem("role"));
      const headers = {
        Authorization: `Bearer ${token}`,
        role: `${role}`,
      };
      const apiUrl = `${GET_MESSAGE}?subject=DailyReading`;
      // Fetch messages from your backend API
      const response = await fetch(apiUrl, {
        method: "GET",
        headers: headers,
      });

      if (response.ok) {
        const fetchedMessages = await response.json();
        // Assuming the response structure is an array of messages

        setMessages(fetchedMessages.data);
        handleClose();
        scrollToBottom();
        console.log("all messages:", fetchedMessages); // Corrected the variable name
      } else {
        // Handle error if needed
        console.error("Error fetching messages:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching messages:", error);
    }
  };

  useEffect(() => {
    fetchMessages();
  }, [0]); // Assuming messages is the array of messages

  useEffect(() => {
    // Scroll to the bottom after messages are updated
    scrollToBottom();
  }, [messages]);

  const messageContainerRef = useRef();
  const scrollToBottom = () => {
    if (messageContainerRef.current) {
      messageContainerRef.current.scrollTop =
        messageContainerRef.current.scrollHeight;
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);

    const file = e.nativeEvent.dataTransfer.files[0]; // Use e.nativeEvent

    // Handle the dropped file
    if (file) {
      setSelectedFile(file);

      const reader = new FileReader();
      reader.onloadend = () => {
        setFilePreview(reader.result);
      };
      reader.readAsDataURL(file);

      uploadfile(file); // If needed, upload the file here

      // Manually trigger the change event on the file input
      const fileInput = document.getElementById("fileInput");
      if (fileInput) {
        fileInput.files = e.dataTransfer.files; // Set files to trigger the change event
        fileInput.dispatchEvent(new Event("change", { bubbles: true }));
      }
    }
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  return (
    <>
      <Layout>
        <div className="screen-container">
          <div className="row">
            <div className="col-12">
              <div className="row mt-3">
                <div className="col-lg-10 col-md-10 col-sm-10 col-xs-2 mx-auto">
                  <div
                    className={`see_messages ${isDragging ? "dragging" : ""}`}
                    onDragOver={handleDragOver}
                    onDrop={handleDrop}
                    onDragLeave={handleDragLeave}
                  >
                    <div className="d-flex mt-1">
                      <img
                        className="profile_pic_msg"
                        src={DailyReadingimg}
                        alt="logo"
                      />
                      <p className="fw-bold message_heading_upper">
                        Daily Reading
                      </p>
                    </div>
                    <hr style={{ margin: "0.5rem 0" }} />
                    <div
                      className="message_container"
                      // style={{
                      //   maxHeight: "60vh",
                      //   overflowY: "auto",
                      // }}
                      ref={messageContainerRef}
                    >
                      {messages.map((message, index) => (
                        <div className="message_box" key={index}>
                          <div className="nameAndDelt justify-content-between d-flex">
                            <p className="fw-bold messenger_name">Admin</p>
                            {isAdmin && (
                              <div className="delt_icon messenger_name d-flex">
                                <button
                                  className="delt_icon messenger_name"
                                  onClick={() => handleEdit(index)}
                                >
                                  <FontAwesomeIcon
                                    icon={faPen}
                                    style={{ color: "#009BFF" }}
                                  />
                                </button>
                                <button
                                  className="delt_icon messenger_name"
                                  onClick={() => handleDelete(index)}
                                >
                                  <FontAwesomeIcon
                                    icon={faTrashAlt}
                                    style={{ color: "red" }}
                                  />
                                </button>
                              </div>
                            )}
                          </div>

                          <div className="message_contant">
                            {message.mediaUrl && (
                              <div
                                style={{ maxWidth: "30%", maxHeight: "25%" }}
                                onClick={() =>
                                  // handleImageClick(message.mediaUrl)
                                  handleMediaClick(
                                    message.mediaUrl,
                                    message.mediaExtension
                                  )
                                }
                              >
                                {(() => {
                                  const mediaExtension = message.mediaExtension;

                                  if (
                                    [
                                      "mp4",
                                      "vlc",
                                      "mkv",
                                      "FLV",
                                      "WebM",
                                      "avi",
                                      "mov",
                                      "3gp",
                                    ].includes(mediaExtension)
                                  ) {
                                    // Render video tag for MOV files
                                    return (
                                      <video
                                        controls
                                        style={{
                                          width: "100%",
                                          height: "100%",
                                          objectFit: "cover",
                                        }}
                                      >
                                        <source
                                          src={message.mediaUrl}
                                          type={`video/${message.mediaExtension}`}
                                        />
                                        Your browser does not support the video
                                        tag.
                                      </video>
                                    );
                                  } else if (
                                    [
                                      "jpg",
                                      "jpeg",
                                      "png",
                                      "PNG",
                                      "gif",
                                      "heic",
                                      "aae",
                                    ].includes(mediaExtension)
                                  ) {
                                    // Render img tag for JPG and PNG files
                                    return (
                                      <img
                                        src={message.mediaUrl}
                                        alt="Media"
                                        style={{
                                          width: "100%",
                                          height: "100%",
                                          objectFit: "cover",
                                        }}
                                      />
                                    );
                                  } else if (
                                    [
                                      "pdf",
                                      "doc",
                                      "docx",
                                      "xls",
                                      "xlsx",
                                      "txt",
                                      "ppt",
                                      "pptx",
                                    ].includes(mediaExtension)
                                  ) {
                                    // Render link tag for PDF and DOC files
                                    return (
                                      <Link
                                        to={message.mediaUrl}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        Open Document
                                      </Link>
                                    );
                                  } else if (
                                    [
                                      "mp3",
                                      "wav",
                                      "pcm",
                                      "aiff",
                                      "aac",
                                    ].includes(mediaExtension)
                                  ) {
                                    // Render audio tag for MP3 and WAV files
                                    return (
                                      <audio controls>
                                        <source
                                          src={message.mediaUrl}
                                          type="audio/mpeg"
                                        />
                                        Your browser does not support the audio
                                        tag.
                                      </audio>
                                    );
                                  } else {
                                    // Default case: render a generic message
                                    return (
                                      <p>{`Unsupported file format: ${mediaExtension}`}</p>
                                    );
                                  }
                                })()}
                              </div>
                            )}
                            {message.message && (
                              <p className="show_messages">{message.message}</p>
                            )}
                          </div>
                          {message.timestamp && (
                            <p className="time_show">
                              {new Date(message.timestamp).toLocaleString([], {
                                year: "numeric",
                                month: "short",
                                day: "numeric",
                                hour: "2-digit",
                                minute: "2-digit",
                              })}
                            </p>
                          )}
                        </div>
                      ))}
                      {filePreview && (
                        <div
                          className={`preview_file ${getFileType()} ${
                            filePreview ? "has-content" : ""
                          }`}
                        >
                          <div className="file-content">
                            {getFileType() === "image" && (
                              <img
                                src={filePreview}
                                alt="File Preview"
                                className="preview_img"
                              />
                            )}
                            {getFileType() === "video" && (
                              <video controls>
                                <source
                                  src={filePreview}
                                  type={selectedFile.type}
                                />
                                Your browser does not support the video tag.
                              </video>
                            )}
                            {getFileType() === "audio" && (
                              <audio controls>
                                <source
                                  src={filePreview}
                                  type={selectedFile.type}
                                />
                                Your browser does not support the audio tag.
                              </audio>
                            )}
                            {getFileType() === "pdf" && (
                              <iframe
                                title="PDF Preview"
                                src={filePreview}
                                width="100%"
                                height="600px"
                              ></iframe>
                            )}
                          </div>
                          <button
                            className="delete-icon"
                            onClick={handleDeleteFile}
                          >
                            <FontAwesomeIcon icon={faTimes} />
                          </button>
                        </div>
                      )}
                    </div>
                    {isAdmin ? (
                      <div>
                        <form onSubmit={handleSubmit} className="mt-auto">
                          <div className="card-footer text-muted d-flex justify-content-start input_div">
                            <div className="input-container">
                              <input
                                type="text"
                                className="  form-control-lg  input-with-padding"
                                id="exampleFormControlInput1"
                                placeholder="Type message"
                                value={inputText}
                                onChange={(e) => setInputText(e.target.value)}
                              />
                              <label
                                className="btn label-with-icon"
                                htmlFor="fileInput"
                              >
                                <FontAwesomeIcon
                                  icon={faPaperclip}
                                  style={{ zIndex: "2" }}
                                />
                                <input
                                  type="file"
                                  id="fileInput"
                                  style={{ display: "none" }}
                                  accept=".mp3, .wav,.jpg,.3gp, .jpeg, .png, .PNG, .gif, .mp4, .mov, .WebM, .FLV, .MKV .avi, .pdf, .doc, .docx, .xls, .xlsx"
                                  onChange={handleFileInputChange}
                                />
                              </label>
                              <button
                                className="send_message"
                                onClick={handleButtonClick}
                              >
                                <FontAwesomeIcon icon={faPaperPlane} />
                              </button>
                            </div>
                          </div>
                        </form>
                        {/* {filePreview && (
                          <div
                            className={`preview_file ${getFileType()} ${
                              filePreview ? "has-content" : ""
                            }`}
                          >
                            <div className="file-content">
                              {getFileType() === "image" && (
                                <img
                                  src={filePreview}
                                  alt="File Preview"
                                  className="preview_img"
                                />
                              )}
                              {getFileType() === "video" && (
                                <video controls>
                                  <source
                                    src={filePreview}
                                    type={selectedFile.type}
                                  />
                                  Your browser does not support the video tag.
                                </video>
                              )}
                              {getFileType() === "audio" && (
                                <audio controls>
                                  <source
                                    src={filePreview}
                                    type={selectedFile.type}
                                  />
                                  Your browser does not support the audio tag.
                                </audio>
                              )}
                              {getFileType() === "pdf" && (
                                <iframe
                                  title="PDF Preview"
                                  src={filePreview}
                                  width="100%"
                                  height="600px"
                                ></iframe>
                              )}
                            </div>
                            <button
                              className="delete-icon"
                              onClick={handleDeleteFile}
                            >
                              <FontAwesomeIcon icon={faTimes} />
                            </button>
                          </div>
                        )} */}
                      </div>
                    ) : (
                      <div className="card-footer text-muted d-flex justify-content-center align-items-center p-3 input_div">
                        {/* <div className="d-flex justify-content-center align-items-center">
                          <FontAwesomeIcon
                            icon={faLock}
                            className="lock-icon"
                          />
                          <p className="ml-2 mb-0 lockdata">
                            Only Admin can send messages here
                          </p>
                        </div> */}
                      </div>
                    )}
                  </div>
                  <Modal
                    show={show}
                    onHide={handleClose}
                    backdrop="static"
                    keyboard={false}
                    dialogClassName="transparent-modal"
                    centered
                  >
                    <img
                      className="loader"
                      src={imageUrl}
                      alt="Loader"
                      style={{ backgroundColor: "none" }}
                    />
                  </Modal>

                  <Modal show={showModal} onHide={handleCloseModal} centered>
                    <Modal.Body>
                      {selectedMedia &&
                        (() => {
                          const { url, extension } = selectedMedia;
                          const mediaType = extension.toLowerCase();

                          if (
                            [
                              "jpg",
                              "jpeg",
                              "png",
                              "PNG",
                              "gif",
                              "heic",
                              "aae",
                            ].includes(mediaType)
                          ) {
                            // Render img tag for images
                            return (
                              <img
                                src={url}
                                alt="Media Preview"
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  objectFit: "contain",
                                }}
                              />
                            );
                          } else if (
                            [
                              "mp4",
                              "vlc",
                              "mkv",
                              "flv",
                              "webm",
                              "avi",
                              "mov",
                              "3gp",
                            ].includes(mediaType)
                          ) {
                            // Render video tag for videos
                            return (
                              <video
                                controls
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  objectFit: "contain",
                                }}
                              >
                                <source src={url} type={`video/${mediaType}`} />
                                Your browser does not support the video tag.
                              </video>
                            );
                          } else {
                            // Default case: render a generic message for unsupported files
                            return (
                              <p>{`Unsupported file format: ${mediaType}`}</p>
                            );
                          }
                        })()}
                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="secondary" onClick={handleCloseModal}>
                        Close
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}

export default DailyReading;
