// import React from "react";
// import Nav from "../Admin/Nav/Header";
// import UserNav from "../Users/UserHeader/UserNav";
// import Footer from "../Footer/Footer";
// import "../Footer/Footer.css";
// import "../Layout/Layout.css";

// const Layout = ({ children }) => {
//   const role = JSON.parse(localStorage.getItem("role"));
//   const isAdmin = role === "admin";

//   return (
//     <div className="layout-container">
//       <div className={`nav-container ${isAdmin ? "admin-nav" : "user-nav"}`}>
//         {isAdmin ? <Nav /> : <UserNav />}
//       </div>
//       <div className="content-container">
//         {children}
//         <Footer className="sticky-footer" />
//       </div>
//     </div>
//   );
// };

// export default Layout;

import React from "react";
import Nav from "../Admin/Nav/Header";
import UserNav from "../Users/UserHeader/UserNav";
import Footer from "../Footer/Footer";
import "../Footer/Footer.css";
import "../Layout/Layout.css";

const Layout = ({ children }) => {
  const role = JSON.parse(localStorage.getItem("role"));
  const isAdmin = role === "admin";

  return (
    <>
      <div className="layout-container">
        <div className={`nav-container ${isAdmin ? "admin-nav" : "user-nav"}`}>
          {isAdmin ? <Nav /> : <UserNav />}
        </div>
        <div className="content-container">
          <div className="content-wrapper">{children}</div>
        </div>
        <Footer className="footer" />
      </div>
    </>
  );
};

export default Layout;
