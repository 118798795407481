import React from "react";
import Layout from "../Layout/Layout";
// import cancellationLogo from "../Assets/cancellationlogo.png";
import leftArrow from "../Assets/leftArrow.png";
import rightArrow from "../Assets/rightArrow.png";

const Cancellation = () => {
  return (
    <>
      <Layout>
        <div className="screen-container">
          <div className="row">
            <div className="col-12">
              <div className="row mt-5">
                <div className="col-lg-10 col-md-10 col-sm-10 col-xs-2 mx-auto text-center d-flex mb-5 center-content">
                  <img
                    className="img-fluid headingArrow"
                    src={rightArrow}
                    alt="logo"
                  />
                  <p className="everyHeading">Cancellation Policy</p>
                  <img
                    className="img-fluid headingArrow"
                    src={leftArrow}
                    alt="logo"
                  />
                </div>
                <div className="col-lg-8 col-md-8 col-sm-8 col-xs-2 mx-auto ">
                  <p
                    className="privacy_Detail"
                    style={{ textAlign: "justify" }}
                  >
                    At 3xstock.com, we believe in safeguarding your personal
                    information and maintaining transparency. This Privacy
                    Policy serves as a guide to help you understand how we, the
                    3xstock.com team, the operators of 3xstock.com, gather,
                    employ, and share Personal Information in our commitment to
                    delivering exceptional services to our clients.
                  </p>
                  <h1 className="privacyHeadings">Your Agreement</h1>
                  <p
                    className="privacy_Detail"
                    style={{ textAlign: "justify" }}
                  >
                    By opting to utilize our services, you agree to our
                    practices outlined in this policy, which involve the
                    collection and utilization of your information. Our use of
                    your Personal Information is focused on enhancing and
                    delivering the service we provide, and we are dedicated to
                    ensuring that your information remains confidential and
                    secure.
                  </p>
                  <h1 className="privacyHeadings">
                    Information We Collect and How We Use It
                  </h1>
                  <p
                    className="privacy_Detail"
                    style={{ textAlign: "justify" }}
                  >
                    To provide you with an optimized user experience, we may
                    request specific personally identifiable information. This
                    may include details such as your name, phone number, and
                    postal address, and will be used solely for the purpose of
                    contacting or identifying you.
                  </p>
                  <h1 className="privacyHeadings">Log Data</h1>
                  <p
                    className="privacy_Detail"
                    style={{ textAlign: "justify" }}
                  >
                    When you access our service, we gather Log Data, which is
                    information sent by your web browser. This data may include
                    your computer's Internet Protocol ("IP") address, browser
                    version, the pages you visit, the time and date of your
                    visit, the duration spent on those pages, and other
                    statistical information.
                  </p>
                  <h1 className="privacyHeadings">Cookies</h1>
                  <p
                    className="privacy_Detail"
                    style={{ textAlign: "justify" }}
                  >
                    Our website employs "cookies," which are small data files
                    that act as anonymous unique identifiers. These cookies are
                    sent from the website you visit to your web browser and are
                    stored on your computer's hard drive. You have the option to
                    accept or decline these cookies and can configure your
                    browser to notify you when a cookie is being sent to your
                    computer. It is important to be aware that declining our
                    cookies may limit your access to certain parts of our
                    service.
                  </p>
                  <h1 className="privacyHeadings">Service Providers</h1>
                  <p
                    className="privacy_Detail"
                    style={{ textAlign: "justify" }}
                  >
                    To enhance our service, we may collaborate with third-party
                    entities and individuals for various purposes, including
                    service facilitation, service provision on our behalf,
                    performance of service-related tasks, or assistance in
                    analyzing how our service is used. These third parties may
                    have access to your Personal Information, but they are
                    legally bound to not disclose or utilize this information
                    for any other purpose aside from their assigned tasks.
                  </p>
                  <h1 className="privacyHeadings">External Links</h1>
                  <p
                    className="privacy_Detail"
                    style={{ textAlign: "justify" }}
                  >
                    Our service may incorporate links to external websites.
                    Clicking on a third-party link will redirect you to their
                    site. Please note that these external sites are not operated
                    by us, so it's advisable to review their Privacy Policy. We
                    have no control over their content, privacy policies, or
                    practices.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default Cancellation;
