import React, { useState, useEffect } from "react";
import Layout from "../../Layout/Layout";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import "../AdminHome/Home.css";
import "../PaymentLog/PaymentLog.css";
import { PAYMENT_LOGS } from "../../Common/Constants";

const PaymentLog = () => {
  // const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  // const [startDate, endDate] = dateRange;

  const [paymentPlans, setPaymentPlans] = useState([]);

  const fetchPaymentPlans = async () => {
    const role = JSON.parse(localStorage.getItem("role"));

    try {
      // Format the dates to be sent in the query parameters
      const formattedStartDate = startDate ? startDate.toISOString() : "";
      const formattedEndDate = endDate ? endDate.toISOString() : "";

      // Construct the URL with query parameters based on selected dates
      const url = `${PAYMENT_LOGS}?startDate=${formattedStartDate}&endDate=${formattedEndDate}`;

      console.log("++++++", url);

      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("globalToken")}`,
          role: `${role}`,
          "Content-Type": "application/json",
          // Add any necessary headers (e.g., authorization) here
        },
      });

      if (response.ok) {
        const data = await response.json();

        setPaymentPlans(data.data || []);
        console.log("Payment Plans API Response:", data.data);
      } else {
        console.error("Failed to fetch payment plans:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching payment plans:", error.message);
    }
  };

  useEffect(() => {
    fetchPaymentPlans();
  }, [startDate, endDate]); // Empty dependency array ensures the effect runs once when the component mounts

  return (
    <>
      <Layout>
        <div className="screen-container">
          <div className="row">
            <div className="col-12">
              <div className="row mt-5">
                <div className="col-10 mt-3">
                  <div className="d-flex justify-content-end ">
                    <DatePicker
                      className="Date_Range_PaymentLog"
                      placeholderText="Select the Date Range"
                      selectsRange={true}
                      startDate={startDate}
                      endDate={endDate}
                      onChange={(update) => {
                        setStartDate(update[0]);
                        setEndDate(update[1]);
                      }}
                    />
                  </div>
                </div>
                <div className="col-lg-8 col-md-8 col-sm-8  mt-3 mx-auto table_div_paymentlog">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>User Name</th>
                        <th>User ID</th>
                        <th>Payment Amount</th>
                        <th>Start Date </th>
                        <th>End Date</th>
                        <th>Payment type</th>
                        <th> Payment detail</th>
                      </tr>
                    </thead>
                    <tbody>
                      {paymentPlans.map((plan, index) => (
                        <tr key={index}>
                          <td>{plan.username}</td>
                          <td>{plan.subscriptionId}</td>
                          <td>{`$${plan.amount.toFixed(2)}`}</td>
                          <td>
                            {new Date(plan.startDate).toLocaleDateString()}
                          </td>
                          <td>{new Date(plan.endDate).toLocaleDateString()}</td>
                          <td>{plan.name}</td>
                          <td>{plan.status}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default PaymentLog;
