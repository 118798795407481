import React, { useState } from "react";
import "../ContactUs/ContactUs.css";
import Layout from "../Layout/Layout";
import Swal from "sweetalert2";
// import contactUs from "../Assets/contactus.png";
import leftArrow from "../Assets/leftArrow.png";
import rightArrow from "../Assets/rightArrow.png";
import { CONTACT_US } from "../Common/Constants";
// import logo from "../Assets/logo.png";
// import envelope from "../Assets/mail.png";

const ContactUs = () => {
  const [sname, setSname] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate form fields
    if (!sname || !email || !message) {
      // alert("Name, Email, and Message are required!");
      Swal.fire({
        icon: "error",
        title: "Validation Error",
        text: "Name, Email, and Message are required!",
      });
      return;
    }

    // Prepare payload
    const payload = {
      name: sname,
      phone: phone,
      email: email,
      message: message,
    };

    try {
      // Make API request
      const response = await fetch(CONTACT_US, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // Add any other headers if needed
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        // Handle success
        Swal.fire({
          icon: "success",
          title: "Message Sent",
          text: "Message has been sent successfully",
          confirmButtonText: "OK",
          customClass: {
            confirmButton: "custom-confirm-button-class",
          },
        });

        // Clear form fields
        setSname("");
        setPhone("");
        setEmail("");
        setMessage("");
      } else {
        // Handle errors
        const errorData = await response.json();
        Swal.fire({
          icon: "error",
          title: "API Error",
          text: `Failed to send message. ${
            errorData.message || "Please try again."
          }`,
        });
      }
    } catch (error) {
      console.error("Error sending message:", error);
      Swal.fire({
        icon: "error",
        title: "Network Error",
        text: "Failed to send message. Please check your network connection and try again.",
      });
    }
  };

  return (
    <>
      <Layout>
        <div className="screen-container">
          <div className="row">
            <div className="col-12">
              <div className="row mt-5">
                <div className="col-lg-10 col-md-10 col-sm-10 col-xs-2 mx-auto text-center d-flex mb-2 center-content">
                  <img
                    className="img-fluid headingArrow"
                    src={rightArrow}
                    alt="logo"
                  />
                  <p className="everyHeading">QUeries</p>
                  <img
                    className="img-fluid headingArrow"
                    src={leftArrow}
                    alt="logo"
                  />
                </div>
                <div className="col-lg-10 col-md-12 col-sm-12 col-xs-2 mt-3 mx-auto text-center">
                  <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-2 mt-1 mx-auto text-center">
                      <div className="contact_message_div d-flex justify-content-center mx-auto">
                        <div className="contact_message_div_inner">
                          <p className="send_message_heading mt-4">
                            Send us a query
                          </p>
                          <form onSubmit={handleSubmit}>
                            <div className="d-flex contact_us_input_div">
                              <input
                                type="text"
                                placeholder="Your Name"
                                className="contact_message_input"
                                value={sname}
                                onChange={(e) => setSname(e.target.value)}
                                required
                              />
                              <input
                                type="text"
                                placeholder="Your Phone"
                                className=" contact_message_input"
                                value={phone}
                                onChange={(e) => setPhone(e.target.value)}
                              />
                            </div>
                            <div className="d-flex">
                              <input
                                type="text"
                                placeholder="Your Email"
                                className=" contact_message_input"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                              />
                            </div>
                            <div className="d-flex">
                              <textarea
                                placeholder="Let us know what you need"
                                className="contact_message_input message_inbox"
                                style={{ height: "100px", paddingLeft: "10px" }}
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                                required
                              />
                            </div>
                            <button
                              type="submit"
                              className="btn send_message_btn"
                            >
                              Send Message
                            </button>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-2 mt-3 mx-auto text-center"></div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default ContactUs;
