import React from "react";
import Layout from "../../Layout/Layout";
import "../Rescipt/Rescipt.css";

import colorlogo from "../../Assets/logo.png";
import tick from "../../Assets/tick.png";

const Rescipt = () => {
  return (
    <>
      <Layout>
        <div className="screen-container">
          <div className="row">
            <div className="col-12">
              <div className="row mt-5">
                <div className="col-lg-10 col-md-10 col-sm-10 col-xs-2 mt-4 mx-auto text-center">
                  <img className="img-fluid" src={colorlogo} alt="logo" />
                </div>
                <div className="col-lg-10 col-md-10 col-sm-10 col-xs-2 mt-5 mx-auto text-center">
                  <img className="img-fluid" src={tick} alt="logo" />
                </div>
                <div className="col-lg-10 col-md-10 col-sm-10 col-xs-2 mx-auto text-center">
                  <p className="rescipt_Headline">Transaction Successful</p>
                </div>
                <div className="col-lg-10 col-md-10 col-sm-10 col-xs-2 mx-auto text-center">
                  <hr />
                </div>
                <div className="col-lg-10 col-md-10 col-sm-10 col-xs-2 mx-auto text-center">
                  <p className="rescipt_detail_heading">
                    Transaction Successful
                  </p>
                </div>
                <div className="col-lg-10 col-md-10 col-sm-10 col-xs-2 mx-auto text-center">
                  <div className="row">
                    <div className="col-6">
                      <p className="rescipt_subheading">Transaction ID</p>
                    </div>
                    <div className="col-6">
                      <p className="rescipt_subheading_detail">24112446150</p>
                    </div>
                    <div className="col-6">
                      <p className="rescipt_subheading">Date & Time</p>
                    </div>
                    <div className="col-6">
                      <p className="rescipt_subheading_detail">
                        06-Oct-2023 18:11:47
                      </p>
                    </div>
                    <div className="col-6">
                      <p className="rescipt_subheading">Sender Name</p>
                    </div>
                    <div className="col-6">
                      <p className="rescipt_subheading_detail">John Deo</p>
                    </div>
                    <div className="col-6">
                      <p className="rescipt_subheading">Transfer amount</p>
                    </div>
                    <div className="col-6">
                      <p className="rescipt_subheading_detail">$ 450.00</p>
                    </div>
                    <div className="col-6">
                      <p className="rescipt_subheading_detail_total">Total</p>
                    </div>
                    <div className="col-6">
                      <p className="rescipt_subheading_detail_total">
                        $ 450.00
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default Rescipt;
