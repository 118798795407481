import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { Navbar, Nav, NavDropdown } from "react-bootstrap";

import logo from "../../Assets/logo.png";
import profile_pic from "../../Assets/profilePic.png";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faNewspaper } from "@fortawesome/free-regular-svg-icons";
import {
  faFileInvoiceDollar,
  faArrowRightFromBracket,
  faMoneyBillTrendUp,
  faChartBar,
  faChartPie,
  faGlobeAmericas,
} from "@fortawesome/free-solid-svg-icons";

import "../UserHeader/UserNav.css";
import { LOG_OUT, SINGLE_USER } from "../../Common/Constants";

const UserNav = () => {
  const [userData, setUserData] = useState(null);
  const navigate = useNavigate();

  const role = JSON.parse(localStorage.getItem("role"));
  const name = JSON.parse(localStorage.getItem("clientName"));
  const [isDropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  const handleMarketAnalysisClick = () => {
    const role = JSON.parse(localStorage.getItem("role"));
    if (role === "admin") {
      navigate("/MarketAnalysis");
    } else if (role === "client") {
      const status = localStorage.getItem("status");

      if (status === "subscribed" || status === "guest") {
        navigate("/MarketAnalysis");
      } else {
        navigate("/Packages");
      }
    } else {
      navigate("/UserLogin");
    }
  };
  const handleStockPageClick = () => {
    const role = JSON.parse(localStorage.getItem("role"));
    const status = localStorage.getItem("status");
    if (role === "admin") {
      navigate("/StockIdea");
    } else if (role === "client" || status === "guest") {
      const status = localStorage.getItem("status");

      if (status === "subscribed") {
        navigate("/StockIdea");
      } else {
        navigate("/Packages");
      }
    } else {
      navigate("/UserLogin");
    }
  };
  const handleOptionPageClick = () => {
    const role = JSON.parse(localStorage.getItem("role"));
    const status = localStorage.getItem("status");

    if (role === "admin") {
      navigate("/OptionSpace");
    } else if (role === "client" || status === "guest") {
      const status = localStorage.getItem("status");

      if (status === "subscribed") {
        navigate("/OptionSpace");
      } else {
        navigate("/Packages");
      }
    } else {
      navigate("/UserLogin");
    }
  };
  const handleDailyReadingPageClick = () => {
    const role = JSON.parse(localStorage.getItem("role"));
    const status = localStorage.getItem("status");

    if (role === "admin") {
      navigate("/DailyReading");
    } else if (role === "client" || status === "guest") {
      const status = localStorage.getItem("status");

      if (status === "subscribed") {
        navigate("/DailyReading");
      } else {
        navigate("/Packages");
      }
    } else {
      navigate("/UserLogin");
    }
  };

  const isClient = role === "client";
  console.log("role is ", isClient);

  const handleLogout = async () => {
    const token = localStorage.getItem("globalToken");
    const role = JSON.parse(localStorage.getItem("role"));
    if (!token) {
      console.error("Token not available");
      return;
    }
    try {
      const response = await fetch(LOG_OUT, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          role: `${role}`,
          "Content-Type": "application/json",
        },
      });

      if (response.ok) {
        localStorage.clear();

        navigate("/");
      } else {
        console.error("Failed to logout:", response.statusText);
      }
    } catch (error) {
      console.error("Error during logout:", error.message);
    }
  };

  const fetchData = async () => {
    const role = JSON.parse(localStorage.getItem("role"));
    const _id = JSON.parse(localStorage.getItem("_id"));
    const apiUrl = `${SINGLE_USER}?_id=${_id}`;

    console.log("role:", role);
    console.log("_id:", _id);
    console.log("apiUrl:", apiUrl);

    try {
      const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("globalToken")}`,
          // role: `${role}`,
          "Content-Type": "application/json",
          // Add any additional headers if needed
        },
      });

      if (response.ok) {
        const responseData = await response.json();
        setUserData(responseData.data[0]); // Fix here, use responseData.data[0]
        console.log("data is :", responseData);
        localStorage.setItem("status", responseData.data[0].status);
      } else {
        console.error("Failed to fetch data:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  useEffect(() => {
    // Call the fetch function
    fetchData();
  }, []); // Empty dependency array

  return (
    <>
      <Navbar expand="lg" bg="light" variant="light" className="nav_border">
        <Navbar.Brand as={Link} to="/">
          <img className="logo" src={logo} alt="logo" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            <Nav.Link as={Link} to="/" className="navLink first_navLink">
              HOME
            </Nav.Link>
            <NavDropdown
              title={
                <span
                  style={{
                    fontFamily: "Patua One",
                    fontSize: "18px",
                    fontWeight: "200",
                    fontStyle: "normal",
                    lineHeight: "normal",
                    letterSpacing: "2.16px",
                  }}
                >
                  BLOGS
                </span>
              }
              id="basic-nav-dropdown"
              className="navDropdown navLink"
            >
              <NavDropdown.Item
                href="#"
                onClick={handleMarketAnalysisClick}
                className="dropdown_item"
              >
                <FontAwesomeIcon
                  icon={faMoneyBillTrendUp}
                  className="icon_right_margin"
                />
                Market Analysis
              </NavDropdown.Item>
              <hr className="navhr" />
              <NavDropdown.Item
                href="#"
                className="dropdown_item"
                onClick={handleStockPageClick}
              >
                <FontAwesomeIcon
                  icon={faChartPie}
                  className="icon_right_margin"
                />
                Stock/ETF Idea
              </NavDropdown.Item>
              <hr className="navhr" />
              <NavDropdown.Item
                href="#"
                className="dropdown_item"
                onClick={handleOptionPageClick}
              >
                <FontAwesomeIcon
                  icon={faChartBar}
                  className="icon_right_margin"
                />
                Option Space
              </NavDropdown.Item>
              <hr className="navhr" />
              <NavDropdown.Item
                href="#"
                className="dropdown_item"
                onClick={handleDailyReadingPageClick}
              >
                <FontAwesomeIcon
                  icon={faGlobeAmericas}
                  className="icon_right_margin"
                />
                Daily Reading
              </NavDropdown.Item>
            </NavDropdown>
            <Nav.Link as={Link} to="/Education" className="navLink">
              EDUCATION
            </Nav.Link>
            <NavDropdown
              title={
                <span
                  style={{
                    fontFamily: "Patua One",
                    fontSize: "18px",
                    fontWeight: "200",
                    fontStyle: "normal",
                    lineHeight: "normal",
                    letterSpacing: "2.16px",
                  }}
                >
                  MORE
                </span>
              }
              id="basic-nav-dropdown"
              className="navDropdown navLink"
            >
              <NavDropdown.Item
                as={Link}
                to="/AboutUs"
                className="dropdown_item"
              >
                About Us
              </NavDropdown.Item>
              <hr className="navhr" />
              <NavDropdown.Item
                as={Link}
                to="/ContactUs"
                className="dropdown_item"
              >
                Contact Us
              </NavDropdown.Item>
              <hr className="navhr" />
              <NavDropdown.Item
                as={Link}
                to="/Disclaimer"
                className="dropdown_item"
              >
                Disclaimer
              </NavDropdown.Item>
              <hr className="navhr" />
              <NavDropdown.Item
                as={Link}
                to="/PrivacyPolicy"
                className="dropdown_item"
              >
                Privacy
              </NavDropdown.Item>
              <hr className="navhr" />
              <NavDropdown.Item
                as={Link}
                to="/Cancellation"
                className="dropdown_item"
              >
                Cancellation Policy
              </NavDropdown.Item>
            </NavDropdown>
            <div className="profile_pic_div contactbtn_div d-flex">
              {isClient ? (
                <div className="img_dropdown profile_dropDown">
                  <div onClick={toggleDropdown} className="img_div">
                    <img
                      className="profile_pic"
                      src={userData?.imageUrl || profile_pic}
                      alt="logo"
                    />
                  </div>
                  <div>
                    <NavDropdown
                      title={name}
                      id="basic-nav-dropdown"
                      className="profileName profileNavbar"
                      show={isDropdownOpen}
                      onToggle={toggleDropdown}
                    >
                      <NavDropdown.Item
                        as={Link}
                        to="/Profile"
                        className="dropdown_item_profile my_profile_heading"
                      >
                        <FontAwesomeIcon
                          icon={faUser}
                          className="profile_icons my_profile_icon"
                        />
                        My Profile
                      </NavDropdown.Item>
                      <hr className="mb-0 mt-1" />
                      <NavDropdown.Item
                        as={Link}
                        to="/Billing"
                        className="dropdown_item_profile"
                      >
                        <FontAwesomeIcon
                          icon={faFileInvoiceDollar}
                          className="profile_icons"
                        />
                        Billing
                      </NavDropdown.Item>
                      <hr className="mb-0 mt-1" />
                      <NavDropdown.Item
                        as={Link}
                        to="/SubscriptionPlan"
                        className="dropdown_item_profile"
                      >
                        <FontAwesomeIcon
                          icon={faNewspaper}
                          className="profile_icons"
                        />
                        Subscription Plan
                      </NavDropdown.Item>
                      <hr className="mb-0 mt-1" />
                      <NavDropdown.Item
                        href="#"
                        className="dropdown_item_profile"
                        onClick={handleLogout}
                      >
                        <FontAwesomeIcon
                          icon={faArrowRightFromBracket}
                          className="profile_icons"
                        />
                        Logout
                      </NavDropdown.Item>
                    </NavDropdown>
                  </div>
                </div>
              ) : (
                <div className="loginOrSignup">
                  <p className="login_signup">
                    <Link to="/UserLogin" className="login_signin_button">
                      <button>Login</button>
                    </Link>
                    or
                    <Link to="/UserSignUp" className="login_signup_button">
                      <button>Signup</button>
                    </Link>
                  </p>
                </div>
              )}
              {/* add the contact btn
              <div>
                <Button
                  className="contactbtn"
                  onClick={handleNavigateToContactUs}
                >
                  Contact Us
                </Button>
              </div> */}
            </div>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </>
  );
};

export default UserNav;
