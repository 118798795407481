import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import logo from "../../Assets/logo.png";
import profile_pic from "../../Assets/profilePic.png";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faUser, faNewspaper } from "@fortawesome/free-regular-svg-icons";
import {
  // faFileInvoiceDollar,
  faArrowRightFromBracket,
  faMoneyBillTrendUp,
  faChartBar,
  faChartPie,
  faGlobeAmericas,
} from "@fortawesome/free-solid-svg-icons";

import "../Nav/Header.css";
import { LOG_OUT } from "../../Common/Constants";

const Header = () => {
  const navigate = useNavigate();

  const [isDropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  const handleLogout = async () => {
    const token = localStorage.getItem("globalToken");
    const role = JSON.parse(localStorage.getItem("role"));
    if (!token) {
      console.error("Token not available");
      return;
    }
    try {
      const response = await fetch(LOG_OUT, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          role: `${role}`,
          "Content-Type": "application/json",
        },
      });

      if (response.ok) {
        localStorage.clear();

        navigate("/admin");
      } else {
        console.error("Failed to logout:", response.statusText);
        Swal.fire({
          title: "Error!",
          text: "Network Error",
          icon: "error",
          confirmButtonText: "OK",
          customClass: {
            confirmButton: "custom-confirm-button-class",
          },
        });
      }
    } catch (error) {
      console.error("Error during logout:", error.message);
      Swal.fire({
        title: "Error!",
        text: "Network Error",
        icon: "error",
        confirmButtonText: "OK",
        customClass: {
          confirmButton: "custom-confirm-button-class",
        },
      });
    }
  };

  return (
    <>
      <Navbar expand="lg" bg="light" variant="light">
        <Navbar.Brand as={Link} to="/home">
          <img className="logo" src={logo} alt="logo" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            <Nav.Link as={Link} to="/home" className="navLink first_navLink">
              Home
            </Nav.Link>
            <NavDropdown
              title={
                <span
                  style={{
                    fontFamily: "Patua One",
                    fontSize: "18px",
                    fontWeight: "200",
                    fontStyle: "normal",
                    lineHeight: "normal",
                    letterSpacing: "2.16px",
                  }}
                >
                  Blogs
                </span>
              }
              id="basic-nav-dropdown"
              className="navDropdown navLink"
            >
              <NavDropdown.Item
                as={Link}
                to="/MarketAnalysis"
                className="dropdown_item"
              >
                <FontAwesomeIcon
                  icon={faMoneyBillTrendUp}
                  className="icon_right_margin"
                />
                Market Analysis
              </NavDropdown.Item>
              <hr className="navhr" />
              <NavDropdown.Item
                as={Link}
                to="/StockIdea"
                className="dropdown_item"
              >
                <FontAwesomeIcon
                  icon={faChartPie}
                  className="icon_right_margin"
                />
                Stock/ETF Idea
              </NavDropdown.Item>
              <hr className="navhr" />
              <NavDropdown.Item
                as={Link}
                to="/OptionSpace"
                className="dropdown_item"
              >
                <FontAwesomeIcon
                  icon={faChartBar}
                  className="icon_right_margin"
                />
                Option Space
              </NavDropdown.Item>
              <hr className="navhr" />
              <NavDropdown.Item
                as={Link}
                to="/DailyReading"
                className="dropdown_item"
              >
                <FontAwesomeIcon
                  icon={faGlobeAmericas}
                  className="icon_right_margin"
                />
                Daily Reading
              </NavDropdown.Item>
            </NavDropdown>
            <Nav.Link as={Link} to="/Education" className="navLink">
              Education
            </Nav.Link>
            <Nav.Link
              as={Link}
              to="/PaymentLog"
              className="navLink"
              style={{ whiteSpace: "nowrap" }}
            >
              Payment Log
            </Nav.Link>
            <div className="profile_pic_div img_div" onClick={toggleDropdown}>
              <img
                className="profile_pic ProfileRight"
                src={profile_pic}
                alt="logo"
              />
              <NavDropdown
                title="Admin"
                id="basic-nav-dropdown"
                className="profileName"
                show={isDropdownOpen}
                onToggle={toggleDropdown}
              >
                {/* <NavDropdown.Item href="#" className="dropdown_item_profile">
                  <FontAwesomeIcon icon={faUser} className="profile_icons" />
                  My Profile
                </NavDropdown.Item>
                <NavDropdown.Item href="#" className="dropdown_item_profile">
                  <FontAwesomeIcon
                    icon={faFileInvoiceDollar}
                    className="profile_icons"
                  />
                  Billing
                </NavDropdown.Item>
                <NavDropdown.Item href="#" className="dropdown_item_profile">
                  <FontAwesomeIcon
                    icon={faNewspaper}
                    className="profile_icons"
                  />
                  Subscription Plan
                </NavDropdown.Item> */}
                <NavDropdown.Item
                  href="#"
                  className="dropdown_item_profile"
                  onClick={handleLogout}
                >
                  <FontAwesomeIcon
                    icon={faArrowRightFromBracket}
                    className="profile_icons"
                  />
                  Logout
                </NavDropdown.Item>
              </NavDropdown>
            </div>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </>
  );
};

export default Header;
