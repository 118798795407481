import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import Login from "../Admin/AdminLogin/AdminLogin";
import Home from "../Admin/AdminHome/Home";
import MarketAnalysis from "../Admin/MarketAnalysis/MarketAnalysis";
import StockIdea from "../Admin/StockIdea/StockIdea";
import OptionSpace from "../Admin/OptionSpace/OptionSpace";
import DailyReading from "../Admin/DailyReading/DailyReading";
import PaymentLog from "../Admin/PaymentLog/PaymentLog";
import UserLogin from "../Users/UserLogin/UserLogin";
import Mainpage from "../Users/MainPage/Mainpage";
import AboutUs from "../AboutUs/AboutUs";
import PrivacyPolicy from "../PrivacyPolicy/PrivacyPolicy";
import Disclaimer from "../Disclaimer/Disclaimer";
import Cancellation from "../CancellationPolicy/Cancellation";
import Education from "../Education/Education";
import Packages from "../Users/Packages/Packages";
import UserSignUp from "../Users/UserSignUp/UserSignUp";
import ForgetPassword from "../Users/ForgetPassword/ForgetPassword";
import ValidateOtp from "../Users/ValidateOtp/ValidateOtp";
import UpdatePassword from "../Users/UpdatePassword/UpdatePassword";
import ConfrmUpdatePassword from "../Users/UpdatePassword/ConfrmUpdatePassword";
import CardPayment from "../Users/CardPayment/CardPayment";
import Profile from "../Users/Profile/Profile";
import EditProfile from "../Users/EditProfile/EditProfile";
import Rescipt from "../Users/Rescipt/Rescipt";
import Auth from "../Auth/Auth";
import Footer from "../Footer/Footer";
import ContactUs from "../ContactUs/ContactUs";
import Billing from "../Users/Billing/Billing";
import SubscriptionPlan from "../Users/SubscriptionPlan/SubscriptionPlan";

const AppRouter = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Mainpage />} />
        <Route path="/admin" element={<Login />} />
        <Route path="/UserLogin" element={<UserLogin />} />
        <Route path="/AboutUs" element={<AboutUs />} />
        <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
        <Route path="/Disclaimer" element={<Disclaimer />} />
        <Route path="/Cancellation" element={<Cancellation />} />
        <Route path="/Education" element={<Education />} />
        <Route path="/Packages" element={<Packages />} />
        <Route path="/UserSignUp" element={<UserSignUp />} />
        <Route path="/Footer" element={<Footer />} />
        <Route path="/Updatepassword" element={<UpdatePassword />} />
        <Route path="/ForgetPassword" element={<ForgetPassword />} />
        <Route path="/ContactUs" element={<ContactUs />} />
        <Route path="/Validateotp" element={<ValidateOtp />} />

        <Route
          path="/Confrmupdatepassword"
          element={<ConfrmUpdatePassword />}
        />

        <Route
          path="/Billing"
          element={
            <Auth>
              <Billing />
            </Auth>
          }
        />
        <Route
          path="/SubscriptionPlan"
          element={
            <Auth>
              <SubscriptionPlan />
            </Auth>
          }
        />

        <Route
          path="/Home"
          element={
            <Auth>
              <Home />
            </Auth>
          }
        />
        <Route
          path="/MarketAnalysis"
          element={
            <Auth>
              <MarketAnalysis />
            </Auth>
          }
        />
        <Route
          path="/StockIdea"
          element={
            <Auth>
              <StockIdea />
            </Auth>
          }
        />
        <Route
          path="/OptionSpace"
          element={
            <Auth>
              <OptionSpace />
            </Auth>
          }
        />
        <Route
          path="/DailyReading"
          element={
            <Auth>
              <DailyReading />
            </Auth>
          }
        />
        <Route
          path="/PaymentLog"
          element={
            <Auth>
              <PaymentLog />
            </Auth>
          }
        />

        <Route
          path="/CardPayment"
          element={
            <Auth>
              <CardPayment />
            </Auth>
          }
        />
        <Route
          path="/Profile"
          element={
            <Auth>
              <Profile />
            </Auth>
          }
        />
        <Route
          path="/EditProfile"
          element={
            <Auth>
              <EditProfile />
            </Auth>
          }
        />
        <Route
          path="/Rescipt"
          element={
            <Auth>
              <Rescipt />
            </Auth>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRouter;
