import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock, faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

import "../UpdatePassword/UpdatePassword.css";

// import logo_icon from "../../Assets/logo.png";
// import login_img from "../../Assets/login_img.png";
import back_img from "../../Assets/slider3.jpg";

import { UPDATE_PASSWORD } from "../../Common/Constants";

const UpdatePassword = () => {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [warnPassword, setWarnPassword] = useState(false);
  const [eye, setEye] = useState(true);
  const [passwordType, setPasswordType] = useState("password");
  const [confrmPasswordType, setConfrmPasswordType] = useState("password");

  const navigate = useNavigate(); // Hook for navigation

  const inputEvent = (event) => {
    const { name, value } = event.target;

    // Reset warning when input changes
    setWarnPassword(false);

    if (name === "newPassword") {
      setNewPassword(value);
    } else if (name === "confirmPassword") {
      setConfirmPassword(value);
    }
  };

  const togglePasswordVisibility = () => {
    setPasswordType(passwordType === "password" ? "text" : "password");
    setEye(!eye);
  };

  const toggleConfrmPasswordVisibility = () => {
    setConfrmPasswordType(
      confrmPasswordType === "password" ? "text" : "password"
    );
    setEye(!eye);
  };

  const handleUpdatePassword = async (e) => {
    e.preventDefault();

    // Check if password length is greater than 8 characters
    if (newPassword.length < 8) {
      setWarnPassword(true);
      // Show alert if password is too short
      // alert("Password must be at least 8 characters long");
      Swal.fire({
        title: "Error!",
        text: "Password must be at least 8 characters long",
        icon: "error",
        confirmButtonText: "OK",
        customClass: {
          confirmButton: "custom-confirm-button-class",
        },
      });
      return;
    }

    // Check if passwords match
    if (newPassword !== confirmPassword) {
      setWarnPassword(true);
      // Show alert if passwords do not match
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "New password and confirm password do not match",
      });

      // alert("New password and confirm password do not match");
      return;
    }

    // Get user ID from local storage
    const userId = localStorage.getItem("userId");

    // Check if userId is available
    if (!userId) {
      console.error("User ID not available");
      return;
    }

    // Prepare payload
    const payload = {
      newPassword,
      confirmPassword,
      _id: userId,
    };
    console.log("payload is:", payload);
    try {
      const response = await fetch(UPDATE_PASSWORD, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      const data = await response.json();

      if (response.ok) {
        // If the API call is successful, clear local storage
        localStorage.removeItem("userId");
        localStorage.removeItem("resetEmail");

        // Reset form or navigate to the next step
        setNewPassword("");
        setConfirmPassword("");

        navigate("/Confrmupdatepassword");
      } else {
        // Handle errors, you might want to show an error message
        console.error("Failed to update password:", data.message);
      }
    } catch (error) {
      console.error("Error updating password:", error.message);
    }
  };

  return (
    <>
      <div className="screen-container">
        <div className="img_div_back_ground">
          <div
            className="blur-background"
            style={{
              backgroundImage: `url(${back_img})`,
            }}
          />
          <div className="row ">
            {/* <div className="col-sm-12 col-lg-6 col-md-6 first_container">
            <div className="row">
              <div className="col-12">
                <Link to="/">
                  <img className="img_logo" src={logo_icon} alt="logo" />
                </Link>
              </div>
              <div className="col-12 login_img_div">
                <img className="login_img" src={login_img} alt="logo" />
              </div>
            </div>
          </div> */}
            <div className="col-sm-12 col-lg-12 col-md-12">
              {/* <div className="col-12 logo_display">
              <img className="img_logo" src={logo_icon} alt="logo" />
            </div> */}
              <div className="container">
                <div className="card" style={{ width: "550px" }}>
                  <div className="text admin_heading">
                    <h4 className="admin_h4">Change password</h4>
                  </div>
                  <div className="text login_text">
                    <h3>Reset your account password </h3>
                  </div>
                  <form onSubmit={handleUpdatePassword}>
                    <div className="input-text left_right_margin">
                      <input
                        type={passwordType}
                        style={{ backgroundColor: "#f4f4f4" }}
                        className={` ${warnPassword ? "warning" : ""}`}
                        placeholder="New Password"
                        value={newPassword}
                        onChange={(e) =>
                          inputEvent({
                            target: {
                              name: "newPassword",
                              value: e.target.value,
                            },
                          })
                        }
                        name="newPassword"
                      />
                      <FontAwesomeIcon icon={faLock} className="fa-lock" />
                      <FontAwesomeIcon
                        icon={eye ? faEye : faEyeSlash}
                        onClick={togglePasswordVisibility}
                        className="eye-icon"
                      />
                      <p className="p_under_email_input">
                        Password must be at least 8 characters long
                      </p>
                    </div>
                    <div className="input-text left_right_margin">
                      <input
                        type={confrmPasswordType}
                        style={{ backgroundColor: "#f4f4f4" }}
                        className={` ${warnPassword ? "warning" : ""}`}
                        placeholder="Confirm Password"
                        value={confirmPassword}
                        onChange={(e) =>
                          inputEvent({
                            target: {
                              name: "confirmPassword",
                              value: e.target.value,
                            },
                          })
                        }
                        name="confirmPassword"
                      />
                      <FontAwesomeIcon icon={faLock} className="fa-lock" />
                      <FontAwesomeIcon
                        icon={eye ? faEye : faEyeSlash}
                        onClick={toggleConfrmPasswordVisibility}
                        className="eye-icon"
                      />
                      <p className="p_under_email_input">
                        Password must be at least 8 characters long
                      </p>
                    </div>

                    <div className="buttons left_right_margin bottom_margin_btn top_margin_btn">
                      <button type="submit" className="login_btn_style">
                        Save Password
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UpdatePassword;
