import React from "react";

import { useNavigate } from "react-router-dom";

import "../UpdatePassword/UpdatePassword.css";

// import logo_icon from "../../Assets/logo.png";
// import login_img from "../../Assets/login_img.png";
import back_img from "../../Assets/slider3.jpg";

import lock from "../../Assets/lock.png";

const ConfrmUpdatePassword = () => {
  const navigate = useNavigate(); // Hook for navigation
  const handleGoLogin = () => {
    // Your login logic here

    // Navigate to /userlogin
    navigate("/userlogin");
  };
  return (
    <>
      <div className="screen-container">
        <div className="img_div_back_ground">
          <div
            className="blur-background"
            style={{
              backgroundImage: `url(${back_img})`,
            }}
          />
          <div className="row ">
            {/* <div className="col-sm-12 col-lg-6 col-md-6 first_container">
            <div className="row">
              <div className="col-12">
                <Link to="/">
                  <img className="img_logo" src={logo_icon} alt="logo" />
                </Link>
              </div>
              <div className="col-12 login_img_div">
                <img className="login_img" src={login_img} alt="logo" />
              </div>
            </div>
          </div> */}
            <div className="col-sm-12 col-lg-12 col-md-12">
              {/* <div className="col-12 logo_display">
                <img className="img_logo" src={logo_icon} alt="logo" />
              </div> */}
              <div className="container">
                <div className="card" style={{ width: "550px" }}>
                  <div className="text admin_heading">
                    <h4 className="admin_h4">Change password </h4>
                  </div>
                  <div className="text login_text mt-5">
                    <h3>Password Change Successfully</h3>
                  </div>
                  <div className="text login_text justify-content-center">
                    <img className="img_logo mb-4" src={lock} alt="logo" />
                    <p className="confrm_pass">
                      Your Password has been changed successfully.
                    </p>
                  </div>
                  <div className="buttons left_right_margin mb-5">
                    <button
                      className="login_btn_style2"
                      onClick={handleGoLogin}
                    >
                      Login
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ConfrmUpdatePassword;
