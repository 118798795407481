import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
// import logo_icon from "../../Assets/logo.png";
// import login_img from "../../Assets/login_img.png";
import back_img from "../../Assets/slider3.jpg";

import "../ForgetPassword/ForgetPassword.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import { FORGET_PASSWORD } from "../../Common/Constants";

const ForgetPassword = () => {
  const [inputtext, setinputtext] = useState({
    email: "",
  });

  const [warnemail, setwarnemail] = useState(false);
  const navigate = useNavigate();

  const inputEvent = (event) => {
    const { name, value } = event.target;
    setinputtext((lastValue) => ({
      ...lastValue,
      [name]: value,
    }));

    // Reset warnings when input changes
    if (name === "email") {
      setwarnemail(false);
    }
  };

  const handleForgetPassword = async (e) => {
    e.preventDefault();

    // Email validation using a regular expression
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Check if the email is not empty and is a valid email
    if (!inputtext.email || !emailRegex.test(inputtext.email)) {
      // Set a warning or handle the error as needed
      // alert("Please enter a valid email");
      Swal.fire({
        title: "Error!",
        text: "Please enter a valid email",
        icon: "error",
        confirmButtonText: "OK",
        customClass: {
          confirmButton: "custom-confirm-button-class",
        },
      });
      // For example, you can set state variables to indicate warnings
      setwarnemail(true);
      return;
    }

    // Log the email
    console.log("Email for password reset:", inputtext.email);

    // Construct the payload
    const payload = {
      email: inputtext.email,
    };

    try {
      // Make the API call to reset password
      const response = await fetch(FORGET_PASSWORD, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        // Password reset email sent successfully
        console.log("Password reset email sent successfully!");

        // Save the email in localStorage
        localStorage.setItem("resetEmail", inputtext.email);

        // Navigate to "/validateOtp"
        navigate("/Validateotp");
      } else {
        // Handle errors, you might want to show an error message
        console.error(
          "Failed to send password reset email:",
          response.statusText
        );

        // Extract the error message from the response
        const errorResponse = await response.json();
        const errorMessage =
          errorResponse.message || "Failed to send password reset email";

        // Display the error message using SweetAlert
        Swal.fire({
          title: "Error!",
          text: errorMessage,
          icon: "error",
          confirmButtonText: "OK",
          customClass: {
            confirmButton: "custom-confirm-button-class",
          },
        });

        // Add any additional logic or state updates as needed
      }
    } catch (error) {
      // Handle network errors or other exceptions
      console.error("Error sending password reset email:", error.message);
      // Add any additional logic or state updates as needed
    }
  };
  return (
    <>
      <div className="screen-container">
        <div className="img_div_back_ground">
          <div
            className="blur-background"
            style={{
              backgroundImage: `url(${back_img})`,
            }}
          />
          <div className="row ">
            {/* <div className="col-sm-12 col-lg-6 col-md-6 first_container">
            <div className="row">
              <div className="col-12">
                <Link to="/">
                  <img className="img_logo" src={logo_icon} alt="logo" />
                </Link>
              </div>
              <div className="col-12 login_img_div">
                <img className="login_img" src={login_img} alt="logo" />
              </div>
            </div>
          </div> */}
            <div className="col-sm-12 col-lg-12 col-md-12">
              {/* <div className="col-12 logo_display">
              <img className="img_logo" src={logo_icon} alt="logo" />
            </div> */}
              <div className="container">
                <div className="card">
                  <div className="text admin_heading">
                    <h4 className="admin_h4">Change password</h4>
                  </div>
                  <div className="text login_text">
                    <h3>Enter your email</h3>
                    <p className="forgetpassword_p">
                      Enter your email and we ‘ll send you a verification code
                      to create a new password.
                    </p>
                  </div>
                  <form onSubmit={handleForgetPassword}>
                    <div className="input-text left_right_margin">
                      <input
                        type="text"
                        className={` ${warnemail ? "warning" : ""}`}
                        placeholder="Enter your email"
                        value={inputtext.email}
                        onChange={inputEvent}
                        name="email"
                        style={{ backgroundColor: "#f4f4f4" }}
                      />
                      <FontAwesomeIcon
                        icon={faEnvelope}
                        className="fa-envelope"
                      />
                      <p className="p_under_email_input">
                        We will send you a link that you can open and reset the
                        password
                      </p>
                    </div>

                    <div className="buttons left_right_margin bottom_margin_btn">
                      <button type="submit" className="login_btn_style">
                        Send Verification Code
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgetPassword;
