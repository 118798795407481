import React, { useEffect, useState } from "react";
import Layout from "../../Layout/Layout";
// import profile_logo from "../../Assets/profilrlogo.png";
import leftArrow from "../../Assets/leftArrow.png";
import rightArrow from "../../Assets/rightArrow.png";
import "../Profile/Profile.css";

import profile_pic from "../../Assets/profilePic.png";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { SINGLE_USER } from "../../Common/Constants";

const Profile = () => {
  const navigate = useNavigate();
  const [userData, setUserData] = useState(null);

  const handleEditProfileClick = () => {
    // Navigate to the "EditProfile" screen
    navigate("/EditProfile");
  };

  const handleEditPasswordClick = () => {
    // Navigate to the "EditProfile" screen
    navigate("/ForgetPassword");
  };

  const handleBillingClick = () => {
    // Navigate to the /billing route
    navigate("/Billing");
  };

  const handleSubscriptionPlanClick = () => {
    // Navigate to the /billing route
    navigate("/SubscriptionPlan");
  };

  const fetchData = async () => {
    const role = JSON.parse(localStorage.getItem("role"));
    const _id = JSON.parse(localStorage.getItem("_id"));
    const apiUrl = `${SINGLE_USER}?_id=${_id}`;

    console.log("role:", role);
    console.log("_id:", _id);
    console.log("apiUrl:", apiUrl);

    try {
      const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("globalToken")}`,
          // role: `${role}`,
          "Content-Type": "application/json",
          // Add any additional headers if needed
        },
      });

      if (response.ok) {
        const responseData = await response.json();
        setUserData(responseData.data[0]); // Fix here, use responseData.data[0]
        console.log("data is :", responseData);
      } else {
        console.error("Failed to fetch data:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  useEffect(() => {
    // Call the fetch function
    fetchData();
  }, []); // Empty dependency array

  return (
    <>
      <Layout>
        <div className="screen-container">
          <div className="row">
            <div className="col-12">
              <div className="row mt-5">
                <div className="col-lg-10 col-md-10 col-sm-10 col-xs-2 mx-auto text-center d-flex mb-5 center-content">
                  <img
                    className="img-fluid headingArrow"
                    src={rightArrow}
                    alt="logo"
                  />
                  <p className="everyHeading">Profile</p>
                  <img
                    className="img-fluid headingArrow"
                    src={leftArrow}
                    alt="logo"
                  />
                </div>
                <div className="col-lg-10 col-md-10 col-sm-12 col-xs-2 mx-auto margin_top d-flex ">
                  <button className="active_btn">Profile</button>
                  <button className="inactive_btn" onClick={handleBillingClick}>
                    Billing
                  </button>
                  <button
                    className="inactive_btn"
                    onClick={handleSubscriptionPlanClick}
                  >
                    Subscription Plan
                  </button>
                </div>
                <div className="col-lg-10 col-md-10 col-sm-10 col-xs-2 mx-auto ">
                  <hr />
                </div>
                <div className="col-lg-10 col-md-10 col-sm-10 col-xs-2 mx-auto mt-3 dd-flex">
                  <p className="profile_heading">Your Profile</p>
                  <button
                    className="btn profile_edit_btn"
                    onClick={handleEditProfileClick}
                  >
                    <FontAwesomeIcon
                      icon={faPencilAlt}
                      className="pencil_icon"
                    />
                    Edit Profile
                  </button>
                </div>
                <div className="col-lg-10 col-md-10 col-sm-10 col-xs-2 mx-auto mt-2 center-image">
                  <img
                    className="edit_profile_pic"
                    src={userData?.imageUrl || profile_pic}
                    alt="logo"
                  />
                </div>
                {userData && (
                  <>
                    <div className="col-lg-10 col-md-10 col-sm-12 col-xs-2 mx-auto mt-5 mb-0">
                      <div className="d-flex profile_inner_div">
                        <p className="profile_subheadings">Name:</p>
                        <p className="profile_subheadings_value text-end">
                          {`${userData.firstName} ${userData.lastName}`}
                        </p>
                      </div>
                    </div>
                    <div className="col-lg-10 col-md-10 col-sm-10 col-xs-2 mx-auto mt-3 mb-0">
                      <div className="d-flex profile_inner_div">
                        <p className="profile_subheadings">Email:</p>
                        <p className="profile_subheadings_value text-end">
                          {userData.email}
                        </p>
                      </div>
                    </div>
                    <div className="col-lg-10 col-md-10 col-sm-10 col-xs-2 mx-auto mt-4 mb-0">
                      <div className="d-flex profile_inner_div">
                        <p className="profile_subheadings">WeChat ID:</p>
                        <p className="profile_subheadings_value text-end">
                          {userData.wechatId ? userData.phone : "N/A"}
                        </p>
                      </div>
                    </div>
                  </>
                )}
                <div className="col-lg-10 col-md-10 col-sm-10 col-xs-2 mx-auto margin_top mb-0">
                  <p className="profile_heading">Security </p>
                </div>
                <div className="col-lg-10 col-md-10 col-sm-12 col-xs-2 mx-auto mt-4 mb-0">
                  <div className="dd-flex profile_inner_div_without_hr ">
                    <p className="profile_subheadings">Password</p>
                    <button
                      className="btn profile_edit_btn_password_link "
                      onClick={handleEditPasswordClick}
                    >
                      <FontAwesomeIcon
                        icon={faPencilAlt}
                        className="pencil_icon"
                      />
                      Change Password
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default Profile;
