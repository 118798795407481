import React, { useState, useEffect } from "react";
import Layout from "../../Layout/Layout";
// import profile_logo from "../../Assets/profilrlogo.png";
import leftArrow from "../../Assets/leftArrow.png";
import rightArrow from "../../Assets/rightArrow.png";
import "../EditProfile/EditProfile.css";

import profile_pic from "../../Assets/profilePic.png";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";

import { useNavigate } from "react-router-dom";
import {
  FILE_UPLOAD,
  SINGLE_USER,
  UPDATE_PROFILE,
} from "../../Common/Constants";

const EditProfile = () => {
  const navigate = useNavigate();

  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [email, setEmail] = useState("");
  const [wechatId, setWeChatId] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  // const [selectedFile, setSelectedFile] = useState(null);

  const handleFileInputChange = async (event) => {
    const file = event.target.files[0];

    // Perform the API call if a file is selected
    if (file) {
      try {
        const token = localStorage.getItem("globalToken");
        const userId = JSON.parse(localStorage.getItem("_id")); // Assuming "_id" is stored in local storage
        const headers = {
          Authorization: `Bearer ${token}`,
        };

        // Use the extension as the objectKey and update the formData
        const formData = new FormData();
        formData.append("file", file);
        formData.append("objectKey", `user/${userId}/${file.name}`);

        const response = await fetch(FILE_UPLOAD, {
          method: "POST",
          headers: headers,
          body: formData,
        });

        if (response.ok) {
          const fileUploadResponse = await response.json();

          console.log("Response data:", fileUploadResponse);

          localStorage.setItem("signedUrl", fileUploadResponse.signedUrl);

          // Clear the file input
          event.target.value = "";
        } else {
          console.error("Failed to upload file:", response.statusText);
        }
      } catch (error) {
        console.error("Error uploading file:", error);
      }
    }
  };

  const handleSaveChanges = async () => {
    try {
      const token = localStorage.getItem("globalToken");
      const userId = JSON.parse(localStorage.getItem("_id"));
      const signedUrl = localStorage.getItem("signedUrl");

      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };

      const payload = {
        firstName: fname,
        lastName: lname,
        wechatId: wechatId,
        imageUrl: signedUrl, // Include signedUrl in the payload
      };
      console.log(payload);

      const response = await fetch(`${UPDATE_PROFILE}?_id=${userId}`, {
        method: "POST", // Change the method based on your API requirements
        headers: headers,
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        const responseData = await response.json();
        console.log("API Response:", responseData);

        // Remove signedUrl from local storage
        localStorage.removeItem("signedUrl");

        // Nevigate it to Profile page
        navigate("/Profile");
      } else {
        console.error("Failed to save changes:", response.statusText);
      }
    } catch (error) {
      console.error("Error saving changes:", error.message);
    }
  };

  const handleEditPasswordClick = () => {
    navigate("/ForgetPassword");
  };

  const handleBillingClick = () => {
    // Navigate to the /billing route
    navigate("/Billing");
  };

  const handleSubscriptionPlanClick = () => {
    // Navigate to the /billing route
    navigate("/SubscriptionPlan");
  };

  const handleAccountClick = () => {
    // Navigate to the /billing route
    navigate("/Profile");
  };

  const fetchData = async () => {
    // const role = JSON.parse(localStorage.getItem("role"));
    const _id = JSON.parse(localStorage.getItem("_id"));
    const apiUrl = `${SINGLE_USER}?_id=${_id}`;

    try {
      const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("globalToken")}`,
          // role: `${role}`,
          "Content-Type": "application/json",
          // Add any additional headers if needed
        },
      });

      if (response.ok) {
        const responseData = await response.json();

        console.log("data is :", responseData);
        if (responseData.data && responseData.data.length > 0) {
          // Set the fetched data to state
          const userData = responseData.data[0];
          setFname(userData.firstName);
          setLname(userData.lastName);
          setEmail(userData.email);
          setWeChatId(userData.wechatId);
          setImageUrl(userData.imageUrl);
        } else {
          console.error("No user data found.");
        }
      } else {
        console.error("Failed to fetch data:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  useEffect(() => {
    // Call the fetch function
    fetchData();
  }, []); // Empty dependency array

  return (
    <>
      <Layout>
        <div className="screen-container">
          <div className="row">
            <div className="col-12">
              <div className="row mt-5">
                <div className="col-lg-10 col-md-10 col-sm-10 col-xs-2 mx-auto text-center d-flex mb-5 center-content">
                  <img
                    className="img-fluid headingArrow"
                    src={rightArrow}
                    alt="logo"
                  />
                  <p className="everyHeading">Profile</p>
                  <img
                    className="img-fluid headingArrow"
                    src={leftArrow}
                    alt="logo"
                  />
                </div>
                <div className="col-lg-10 col-md-10 col-sm-12 col-xs-2 mx-auto margin_top d-flex">
                  <button className="active_btn" onClick={handleAccountClick}>
                    Profile
                  </button>
                  <button className="inactive_btn" onClick={handleBillingClick}>
                    Billing
                  </button>
                  <button
                    className="inactive_btn"
                    onClick={handleSubscriptionPlanClick}
                  >
                    Subscription Plan
                  </button>
                </div>
                <div className="col-lg-10 col-md-10 col-sm-10 col-xs-2 mx-auto ">
                  <hr />
                </div>
                <div className="col-lg-10 col-md-10 col-sm-12 col-xs-2 mx-auto mt-3 dd-flex">
                  <p className="profile_heading">Your Profile</p>
                  <button
                    className="btn profile_edit_btns"
                    onClick={handleSaveChanges}
                  >
                    <FontAwesomeIcon
                      icon={faPencilAlt}
                      className="pencil_icon"
                    />
                    Save Changes
                  </button>
                </div>

                {/* <div className="col-lg-10 col-md-10 col-sm-12 col-xs-2 mx-auto mt-2 img_div">
                  <img
                    className="edit_profile_pic"
                    src={imageUrl || profile_pic}
                    alt="logo"
                  />
                  <label className="btn edit_pic_change_image">
                    Change Image
                    <input
                      type="file"
                      style={{ display: "none" }}
                      accept="image/*"
                      onChange={handleFileInputChange}
                    />
                  </label>
                </div> */}

                <div className="col-lg-10 col-md-10 col-sm-12 col-xs-2 mx-auto mt-2 img_div">
                  <div className="d-flex flex-column align-items-center">
                    <img
                      className="edit_profile_pic"
                      src={imageUrl || profile_pic}
                      alt="logo"
                    />
                    <label className="btn edit_pic_change_image mt-2">
                      Change Image
                      <input
                        type="file"
                        style={{ display: "none" }}
                        accept="image/*"
                        onChange={handleFileInputChange}
                      />
                    </label>
                  </div>
                </div>

                <div className="col-lg-10 col-md-10 col-sm-12 col-xs-2 mx-auto mt-5 mb-0">
                  <div className="profile_inner_div ">
                    <p className="profile_subheadings sub-subheading">Name:</p>
                    <div className="d-flex-end">
                      <input
                        type="text"
                        className="edit_profile_name_input"
                        value={fname}
                        onChange={(e) => setFname(e.target.value)}
                      />
                      <input
                        type="text"
                        className="edit_profile_name_input"
                        placeholder="Last Name"
                        value={lname}
                        onChange={(e) => setLname(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-10 col-md-10 col-sm-12 col-xs-2 mx-auto mt-3 mb-0">
                  <div className="dd-flex profile_inner_div">
                    <p className="profile_subheadings sub-subheading">Email:</p>
                    <input
                      type="text"
                      className="edit_email_input"
                      value={email}
                      readOnly
                    />
                  </div>
                </div>
                <div className="col-lg-10 col-md-10 col-sm-12 col-xs-2 mx-auto mt-4 mb-0">
                  <div className="dd-flex profile_inner_div">
                    <p className="profile_subheadings sub-subheading">
                      WeChat ID:
                    </p>
                    <input
                      type="text"
                      className="edit_wechatid_input"
                      placeholder="WeChat ID"
                      value={wechatId}
                      onChange={(e) => setWeChatId(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-lg-10 col-md-10 col-sm-12 col-xs-2 mx-auto margin_top mb-0">
                  <p className="profile_heading">Security </p>
                </div>
                <div className="col-lg-10 col-md-10 col-sm-12 col-xs-2 mx-auto mt-4 mb-0">
                  <div className="dd-flex profile_inner_div_without_hr">
                    <p className="profile_subheadings">Password</p>
                    <button
                      className="btn profile_edit_btn_password"
                      onClick={handleEditPasswordClick}
                    >
                      <FontAwesomeIcon
                        icon={faPencilAlt}
                        className="pencil_icon"
                      />
                      Change Password
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default EditProfile;
