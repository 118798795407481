import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import "../ValidateOtp/ValidateOtp.css";
import Swal from "sweetalert2";
// import logo_icon from "../../Assets/logo.png";
// import login_img from "../../Assets/login_img.png";
import back_img from "../../Assets/slider3.jpg";

import { FORGET_PASSWORD, VALIDATE_OTP } from "../../Common/Constants";

const ValidateOtp = () => {
  const [otpValues, setOtpValues] = useState(["", "", "", "", "", ""]);
  const navigate = useNavigate(); // Hook for navigation

  const handleInputChange = (index, value) => {
    if (/^[0-9]$/.test(value)) {
      // Only update the value if it's a numeric digit
      setOtpValues((prevValues) => {
        const newValues = [...prevValues];
        newValues[index] = value;
        return newValues;
      });

      // Move to the next input if the current input is not the last one
      if (index < otpValues.length - 1 && value !== "") {
        document.getElementById(`otp-input-${index + 1}`).focus();
      }
    }
  };

  // const handleKeyDown = (index, e) => {
  //   // Handle backspace to move to the previous input
  //   if (e.key === "Backspace" && index > 0) {
  //     document.getElementById(`otp-input-${index - 1}`).focus();
  //   }
  // };

  const handleKeyDown = (index, e) => {
    // Handle backspace to move to the previous input and remove value
    if (e.key === "Backspace") {
      e.preventDefault(); // Prevent the default backspace behavior

      // Remove the value in the current input or move to the previous input
      setOtpValues((prevValues) => {
        const newValues = [...prevValues];

        if (index > 0) {
          // Remove the value in the current input
          newValues[index] = "";
          // Move focus to the previous input
          document.getElementById(`otp-input-${index - 1}`).focus();
        } else {
          // If we are at the first input, just remove the value
          newValues[index] = "";
        }

        return newValues;
      });
    }
  };

  const handleValidateOTP = async (e) => {
    e.preventDefault();

    // Concatenate the OTP values into a single string
    const otpString = otpValues.join("");

    // Retrieve reset email from local storage
    const resetEmail = localStorage.getItem("resetEmail");

    // Check if resetEmail is available
    if (!resetEmail) {
      console.error("Reset email not found in local storage");
      // Handle the error as needed
      return;
    }

    // Prepare the payload for the API call
    const payload = {
      email: resetEmail,
      resetCode: otpString,
    };

    try {
      // Make the API call to validate OTP
      const response = await fetch(VALIDATE_OTP, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        // Parse the JSON response
        const responseData = await response.json();

        // Check if the response contains the expected structure
        if (responseData && responseData.data && responseData.data.id) {
          // Extract the ID from the response
          const userId = responseData.data.id;

          // Store the ID in local storage
          localStorage.setItem("userId", userId);

          // Log the ID and success message
          console.log("User ID:", userId);
          console.log("OTP validation successful");
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.onmouseenter = Swal.stopTimer;
              toast.onmouseleave = Swal.resumeTimer;
            },
          });
          Toast.fire({
            icon: "success",
            title: "OTP validation successful",
          });

          // Navigate to the /Updatepassword route
          navigate("/Updatepassword");
        } else {
          // Handle unexpected response format
          console.error("Unexpected response format:", responseData);
        }
      } else {
        // Handle errors, you might want to show an error message
        console.error("Failed to validate OTP:", response.statusText);
        // alert(response.statusText);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: response.statusText,
        });

        // Perform additional error handling as needed
      }
    } catch (error) {
      console.error("Error validating OTP:", error.message);
      // Handle network errors or other exceptions
    }
  };

  const handleResendCode = async () => {
    // Retrieve email from local storage
    const email = localStorage.getItem("resetEmail");

    // Check if email is available
    if (!email) {
      console.error("Reset email not found in local storage");
      // Handle the error as needed
      return;
    }

    // Prepare the payload for the API call
    const payload = {
      email: email,
    };
    console.log("RESEND EMAIL:", payload);
    try {
      // Make the API call to resend code
      const response = await fetch(FORGET_PASSWORD, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        // Handle success, e.g., show a success message
        console.log("send code");
        // Perform additional actions if needed

        // Reload the page after showing the success toast
        window.location.reload();

        // alert(response.statusText);
        Swal.fire({
          icon: "success",
          title: "Send",
          text: "Send Code Successfully",
        });
      } else {
        // Handle errors, you might want to show an error message
        console.error("Failed to resend code:", response.statusText);
        // Perform additional error handling as needed
      }
    } catch (error) {
      console.error("Error resending code:", error.message);
      // Handle network errors or other exceptions
    }
  };

  return (
    <>
      <div className="screen-container">
        <div className="img_div_back_ground">
          <div
            className="blur-background"
            style={{
              backgroundImage: `url(${back_img})`,
            }}
          />
          <div className="row ">
            {/* <div className="col-sm-12 col-lg-6 col-md-6 first_container">
            <div className="row">
              <div className="col-12">
                <Link to="/">
                  <img className="img_logo" src={logo_icon} alt="logo" />
                </Link>
              </div>
              <div className="col-12 login_img_div">
                <img className="login_img" src={login_img} alt="logo" />
              </div>
            </div>
          </div> */}
            <div className="col-sm-12 col-lg-12 col-md-12">
              {/* <div className="col-12 logo_display">
              <img className="img_logo" src={logo_icon} alt="logo" />
            </div> */}
              <div className="container">
                <div className="card">
                  <div className="text admin_heading">
                    <h4 className="admin_h4">Change password</h4>
                  </div>
                  <div className="text login_text">
                    <h3>OTP</h3>
                    <p className="forgetpassword_p">
                      Please enter the verification code sent via email to
                      Example@gmail.com to Reset Password
                    </p>
                  </div>
                  <form onSubmit={handleValidateOTP}>
                    <div className="input-text left_right_margin">
                      <div
                        id="otp"
                        className="inputs d-flex flex-row justify-content-center mt-2"
                      >
                        {otpValues.map((value, index) => (
                          <input
                            key={index}
                            id={`otp-input-${index}`}
                            className="m-2 text-center form-control rounded"
                            type="tel"
                            maxLength="1"
                            value={value}
                            onChange={(e) =>
                              handleInputChange(index, e.target.value)
                            }
                            onKeyDown={(e) => handleKeyDown(index, e)}
                            style={{ backgroundColor: "#f4f4f4" }}
                          />
                        ))}
                      </div>
                      <p className="p_under_email_input">
                        We will send you a link that you can open and reset the
                        password
                      </p>
                    </div>

                    <div className="buttons left_right_margin mb-4">
                      <button type="submit" className="login_btn_style">
                        Verify Code
                      </button>
                    </div>
                  </form>
                  <div className="Forget_password_div mt-0">
                    <p
                      className="Forget_password_link"
                      onClick={handleResendCode}
                    >
                      RESEND CODE
                    </p>
                  </div>
                  <div className="Forget_password_div mt-0">
                    <p>
                      By signing up I agree the
                      <Link
                        to="/PrivacyPolicy"
                        style={{ textDecoration: "none", marginLeft: "8px" }}
                        className="privacy_link"
                      >
                        Privacy policy
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ValidateOtp;
