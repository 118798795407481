import React, { useEffect, useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import "../Education/Education.css";
import Layout from "../Layout/Layout";
import Swal from "sweetalert2";
// import educationLogo from "../Assets/educationlogo.png";
import leftArrow from "../Assets/leftArrow.png";
import rightArrow from "../Assets/rightArrow.png";

import uploadIcon from "../Assets/UploadIcon.png";
import { useDropzone } from "react-dropzone";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import the styles
import { ADD_EDUCATION, FILE_UPLOAD, GET_EDUCATION } from "../Common/Constants";

const Education = () => {
  const [isAdmin, setIsAdmin] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const [educationData, setEducationData] = useState([]);

  // State for form inputs
  const [title, setTitle] = useState("");
  const [text, setText] = useState("");
  const [image, setImage] = useState(null);

  const handleShow = () => setShowModal(true);

  const handleClose = () => {
    // Reset the state values when the modal is closed
    setTitle("");
    setText("");
    setImage(null);
    setShowModal(false);
  };

  const handleFileInputChange = async (file, event) => {
    console.log("Inside handleFileInputChange");
    console.log("File:", file);

    try {
      const token = localStorage.getItem("globalToken");
      const role = JSON.parse(localStorage.getItem("role"));
      const headers = {
        Authorization: `Bearer ${token}`,
        role: `${role}`,
      };

      const formData = new FormData();
      formData.append("file", file);
      formData.append("objectKey", `education/${file.name}`);

      const response = await fetch(FILE_UPLOAD, {
        method: "POST",
        headers: headers,
        body: formData,
      });

      console.log("Response:", response);

      if (response.ok) {
        const fileUploadResponse = await response.json();
        console.log("Response data:", fileUploadResponse);

        localStorage.setItem("signedUrl", fileUploadResponse.signedUrl);
      } else {
        console.error("Failed to upload file:", response.statusText);
      }
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept:
      "image/png, image/jpeg, image/jpg, image/gif, image/heic, image/aae",
    onDrop: (acceptedFiles, event) => {
      console.log("Inside onDrop");
      console.log("Accepted Files:", acceptedFiles);
      console.log("Event:", event);

      if (acceptedFiles.length > 0) {
        setImage(acceptedFiles[0]);
        handleFileInputChange(acceptedFiles[0], event);
      } else {
        console.log("Invalid file type. Please upload a valid image.");
      }
    },
    maxFiles: 1,
  });

  const handleSave = async () => {
    try {
      // Retrieve the signedUrl from localStorage
      const signedUrl = localStorage.getItem("signedUrl");
      console.log("signedUrl:", signedUrl);

      // Perform the API call if the title, description, and signedUrl are available
      if (title && text) {
        const token = localStorage.getItem("globalToken");
        const role = JSON.parse(localStorage.getItem("role"));
        const headers = {
          Authorization: `Bearer ${token}`,
          role: `${role}`,
          "Content-Type": "application/json",
        };

        const payload = {
          title: title,
          body: text,
          mediaUrl: signedUrl,
        };
        console.log(payload);
        const response = await fetch(ADD_EDUCATION, {
          method: "POST",
          headers: headers,
          body: JSON.stringify(payload),
        });

        if (response.ok) {
          const responseData = await response.json();
          console.log("API Response:", responseData);

          // Remove the signedUrl from local storage
          localStorage.removeItem("signedUrl");

          fetchEducationData();
          // Close the modal after successful API call
          handleClose();
        } else {
          console.error("Failed to add education:", response.statusText);
        }
      } else {
        // Alert.error("Incomplete data for API call");
        Swal.fire({
          icon: "error",
          title: "Incomplete data",
          text: "Please fill in all required fields",
        });
        console.error("Incomplete data for API call");
      }
    } catch (error) {
      console.error("Error saving education:", error.message);
    }
  };

  const fetchEducationData = async () => {
    try {
      const response = await fetch(GET_EDUCATION);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      console.log(data.data[0].body);
      setEducationData(data.data); // Assuming the response contains the education data
    } catch (error) {
      console.error("Error fetching education data:", error);
    }
  };

  useEffect(() => {
    // Retrieve role from localStorage
    const role = JSON.parse(localStorage.getItem("role"));

    // Check if the role is "admin"
    setIsAdmin(role === "admin");

    // Call the fetchEducationData function
    fetchEducationData();
  }, []);

  return (
    <>
      <Layout>
        <div className="screen-container">
          <div className="row">
            <div className="col-12">
              <div className="row mt-3">
                <div className="col-lg-10 col-md-10 col-sm-10 col-xs-2 mt-2 mx-auto text-center d-flex center-content">
                  <img
                    className="img-fluid headingArrow"
                    src={rightArrow}
                    alt="logo"
                  />
                  <p className="everyHeading">EDUCATION</p>
                  <img
                    className="img-fluid headingArrow"
                    src={leftArrow}
                    alt="logo"
                  />
                </div>
                <div className="col-lg-7 col-md-7 col-sm-7 col-xs-2 mx-auto mt-3">
                  <div className="d-flex justify-content-between align-items-center">
                    <p className="educationHeading">
                      {educationData.length > 0 ? educationData[0].title : ""}
                    </p>
                    {isAdmin && (
                      <button
                        className="edit_education_btn"
                        onClick={handleShow}
                      >
                        Edit Education
                      </button>
                    )}
                  </div>
                </div>
                <div className="col-lg-8 col-md-8 col-sm-8 col-xs-2 mx-auto mt-3 centered-container">
                  <div className="col-lg-12 col-md-12 col-sm-12 col-xs-2 mx-auto">
                    <img
                      className="img-fluid align-center education_img_size"
                      src={
                        educationData.length > 0
                          ? educationData[0].mediaUrl
                          : ""
                      }
                      alt="logo"
                    />
                  </div>
                  <p
                    className="education_detail"
                    dangerouslySetInnerHTML={{
                      __html:
                        educationData.length > 0
                          ? educationData[0].body
                          : "Default Description",
                    }}
                  />
                </div>

                <Modal show={showModal} onHide={handleClose}>
                  <Modal.Header closeButton>
                    <Modal.Title className="education_modal_title">
                      Edit Education
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Form>
                      <Form.Group controlId="formTitle">
                        {/* <Form.Label>Title</Form.Label> */}
                        <Form.Control
                          className="education_title_Model"
                          type="text"
                          placeholder="Add Title here...."
                          value={title}
                          onChange={(e) => setTitle(e.target.value)}
                        />
                      </Form.Group>

                      <Form.Group controlId="formText">
                        <Form.Label className="education_model_description">
                          Description
                        </Form.Label>
                        <ReactQuill
                          className="model_textarea"
                          theme="snow"
                          value={text}
                          onChange={(value) => setText(value)}
                          modules={{
                            toolbar: [["bold", "italic", "underline"]],
                          }}
                        />
                      </Form.Group>

                      <Form.Group controlId="formImage">
                        <div>
                          <div {...getRootProps()} className="dropzone">
                            <input
                              {...getInputProps()}
                              accept=".png, .jpg, .jpeg, .gif, .heic, .aae"
                              multiple={false}
                            />
                            <img
                              className="img-fluid align-center"
                              src={uploadIcon}
                              alt="logo"
                            />
                            <p className="uploadImg_p">
                              Drag image or
                              <span className="uploadImg_b">Browse</span>
                            </p>
                          </div>

                          {image && (
                            <img
                              src={URL.createObjectURL(image)}
                              alt="Selected"
                              className="selected-image"
                            />
                          )}
                        </div>
                      </Form.Group>

                      <Form.Group>
                        <Button
                          className="btn education_data_save"
                          onClick={handleSave}
                        >
                          Done
                        </Button>
                      </Form.Group>
                    </Form>
                  </Modal.Body>
                </Modal>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default Education;
