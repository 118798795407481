import React, { useEffect, useState } from "react";
import Layout from "../../Layout/Layout";
import Swal from "sweetalert2";
// import profile_logo from "../../Assets/profilrlogo.png";
import leftArrow from "../../Assets/leftArrow.png";
import rightArrow from "../../Assets/rightArrow.png";
import daimond_img from "../../Assets/daimondimg.png";
import active_img from "../../Assets/Active.png";
import "../SubscriptionPlan/SubscriptionPlan.css";
import { useNavigate } from "react-router-dom";
import {
  CANCEL_SUBSCRIPTION,
  GET_PAYMENT_DETAIL,
} from "../../Common/Constants";

const SubscriptionPlan = () => {
  const [subscriptionData, setSubscriptionData] = useState(null);

  const navigate = useNavigate();

  const handleAccountClick = () => {
    // Navigate to the /billing route
    navigate("/Profile");
  };

  const handleBillingClick = () => {
    // Navigate to the /billing route
    navigate("/Billing");
  };

  const fetchDataForDetail = async () => {
    try {
      const token = localStorage.getItem("globalToken");
      const userId = JSON.parse(localStorage.getItem("_id")); // Assuming "_id" is the key for the user ID in local storage
      console.log(
        `API URL is this: ${GET_PAYMENT_DETAIL}?isCurrent=false&userid=${userId}`
      );

      // Make the API call
      const response = await fetch(
        `${GET_PAYMENT_DETAIL}?isCurrent=true&userid=${userId}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.ok) {
        const data = await response.json();

        // Set the data in the component state
        setSubscriptionData(data.data[0]);

        // Log the API response data
        console.log(
          "API Response for current true:",
          subscriptionData.plan.name
        );

        // Add any further processing based on the API response
      } else {
        // Handle errors for the API call
        console.error("Failed to fetch data:", response.statusText);
      }
    } catch (error) {
      // Handle network errors or other exceptions
      console.error("Error fetching data:", error.message);
    }
  };

  const handleCancelSubscription = async () => {
    try {
      const token = localStorage.getItem("globalToken");
      const subscriptionId = subscriptionData.subscriptionId; // Adjust based on your data structure
      // const userId = JSON.parse(localStorage.getItem("_id"));

      // Format the query parameters
      const queryParams = new URLSearchParams({
        subscriptionId: subscriptionId,
      }).toString();

      const cancelResponse = await fetch(
        `${CANCEL_SUBSCRIPTION}?${queryParams}`,
        {
          method: "POST", // or 'DELETE' depending on your API
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          // body: JSON.stringify({
          //   subscriptionId,
          //   userId,
          // }),
        }
      );

      if (cancelResponse.ok) {
        // Handle successful cancellation
        console.log("Subscription canceled successfully");

        window.location.reload();
      } else {
        console.error(
          "Failed to cancel subscription:",
          cancelResponse.statusText
        );
      }
    } catch (error) {
      console.error("Error canceling subscription:", error.message);
    }
  };

  useEffect(() => {
    fetchDataForDetail();

    // Add any dependencies to the dependency array if needed
  }, []);

  const handleCancelPopup = () => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    });

    swalWithBootstrapButtons
      .fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          swalWithBootstrapButtons.fire({
            title: "Deleted!",
            text: "Your subscription is cancel.",
            icon: "success",
          });
          handleCancelSubscription();
          // Add your logic for confirming the cancellation here
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          swalWithBootstrapButtons.fire({
            title: "Cancelled",
            text: "Your subscription is safe :)",
            icon: "error",
          });
          // Add your logic for canceling the cancellation here (if needed)
        }
      });
  };

  return (
    <>
      <Layout>
        <div className="screen-container">
          <div className="row">
            <div className="col-12">
              <div className="row mt-5">
                <div className="col-lg-10 col-md-10 col-sm-12 col-xs-2 mx-auto text-center d-flex mb-5 center-content">
                  <img
                    className="img-fluid headingArrow"
                    src={rightArrow}
                    alt="logo"
                  />
                  <p className="everyHeading">Profile</p>
                  <img
                    className="img-fluid headingArrow"
                    src={leftArrow}
                    alt="logo"
                  />
                </div>
                <div className="col-lg-10 col-md-10 col-sm-10 col-xs-2 mx-auto margin_top d-flex">
                  <button className="inactive_btn" onClick={handleAccountClick}>
                    Profile
                  </button>
                  <button
                    className="inactive_btn "
                    onClick={handleBillingClick}
                  >
                    Billing
                  </button>
                  <button className="active_btn">Subscription Plan</button>
                </div>
                <div className="col-lg-10 col-md-10 col-sm-10 col-xs-2 mx-auto ">
                  <hr />
                </div>

                <div className="col-lg-10 col-md-10 col-sm-12 col-xs-4 mt-3 mx-auto ">
                  {subscriptionData && (
                    <div className="show_card_detail_cancel_div ">
                      <div className="dd-flex">
                        <img
                          className="img-fluid daimond_pic"
                          src={daimond_img}
                          alt="logo"
                        />
                        <div>
                          <p className="plan_name">
                            {subscriptionData.plan.name}
                          </p>
                          <p className="amount_year">
                            {`$${subscriptionData.plan.amount}/`}
                            <span className="monthOrYear">
                              {subscriptionData.plan?.name?.split(" ")[0]}
                            </span>
                          </p>
                        </div>
                        <img
                          className=" active_img"
                          src={active_img}
                          alt="logo"
                        />
                      </div>
                      <div className="">
                        <button
                          className="btn subscription_plan_canel_btn"
                          onClick={handleCancelPopup}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default SubscriptionPlan;
